import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardsData } from '@selectors/dashboards'
import { getIdOrg } from '@selectors/org'
import { EMPTY_ARRAY, EMPTY_OBJECT, GET_DRILL_DOWN_DATA_FOR_WIDGET, ITEMS_PER_PAGE } from '@root/constants'
import Table from '@components/table'
import uniq from 'lodash/uniq'
import { getExportRowLimit, getWidgetTableKey } from '@components/dashboard/widgets/shared'
import { sortStringFromArray } from '@shared/utils'
import { ENTITY_TYPES } from '@reducers/dashboards/types'
import { getEntityDataForWidget } from '@actions/dashboards'
import { DynamicWidgetDataPopupProps } from './types'
import Analytics, { ENTITY_TYPE } from '@components/dashboard/analytics'
import AppDataPopup from './appDataPopup'
import UserDataPopup from './userDataPopup'
import TransactionDataPopup from './transactionDataPopup'
import ContractDataPopup from './contractDataPopup'
import AppUserDataPopup from './appUserDataPopup'
import { FEATURES, getPlanForFeatureTooltipLabel } from '@root/shared/features'
import AccessControl from '@lenses/accessControl'
export const USER_REQUIRED_FIELDS = ['firstName', 'lastName', 'email', 'isExternal', 'creationTime', 'idRole', 'idOrg', 'status', 'lifecycleStatus', 'isDeletedInIdentitySources', 'identitySourcesDeletionTime', 'activeAppsCount', 'photoUrl', 'annualCostConverted', 'additionalEmails']

type Props = Omit<DynamicWidgetDataPopupProps, 'loadingMetadata'>

const DynamicWidgetDataPopupContent = (props: Props): ReactElement => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  const { isEditMode, idDashboard, widget, onWidgetUpdate, dashboardName, dashboardSystemKey, extraFilters } = props
  const { id: idWidget } = widget

  const idOrg = useSelector(getIdOrg)
  const dashboardsData = useSelector(getDashboardsData)

  const isOrgPlanAllowToUpdateWidgetConfig = AccessControl.useIsFeatureEnabledInPlan({ feature: FEATURES.DASHBOARDS.FEATURES.UPDATE_WIDGET_CONFIG })

  const dashboardData = dashboardsData[idDashboard]
  const widgetsDataById = dashboardData?.widgetsData ?? EMPTY_OBJECT
  const widgetData = widgetsDataById[idWidget]

  const { rows = [], total: totalResults } = widgetData?.drillDownData ?? {}

  const combinedFilters = useMemo(() => {
    if (!extraFilters || extraFilters.length === 0) {
      return widget.dataConfig.filters
    }

    const combined = [...widget.dataConfig.filters, ...extraFilters]
    return combined
  }, [widget.dataConfig.filters, extraFilters])

  const getDrillDownData = useCallback(async ({ limit = ITEMS_PER_PAGE, offset = 0, reset = false }) => {
    await dispatch(getEntityDataForWidget({
      actionTarget: GET_DRILL_DOWN_DATA_FOR_WIDGET,
      entityType: widget.entityType,
      idOrg,
      idWidget,
      idDashboard,
      filters: combinedFilters,
      fields: widget.entityType === ENTITY_TYPES.USERS ? uniq([...USER_REQUIRED_FIELDS, ...widget.drillDownConfig.fields]) : undefined,
      sort: widget.drillDownConfig.sort,
      offset,
      reset,
      limit
    }))
  }, [combinedFilters, dispatch, idDashboard, idOrg, idWidget, widget.drillDownConfig.fields, widget.drillDownConfig.sort, widget.entityType])

  const fetchData = useCallback(async (reset = false) => {
    if (reset) {
      setIsLoading(true)
    } else {
      setLoadingMore(true)
    }
    await getDrillDownData({ offset: reset ? 0 : rows.length, reset })
    setIsLoading(false)
    setLoadingMore(false)
  }, [rows.length, getDrillDownData])

  useEffect(() => {
    if (idOrg && idWidget) {
      fetchData(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idOrg, idWidget, widget])

  const renderFunc = ({ columns, filtersOptions, fieldValues, configurableColumnsOptions, fetchFieldValues, exportToCsv, loadingMetadata }) => {
    if (loadingMetadata) {
      return null
    }

    const onColumnSelectionChange = (fields) => {
      onWidgetUpdate({ updatedWidget: { ...widget, drillDownConfig: { ...widget.drillDownConfig, fields } } })
    }

    const onFilterChange = (filters) => {
      Analytics.updateWidgetConfiguration({ dashboardName, dashboardSystemKey, widgetTitle: widget?.displayConfig.label, fieldName: 'filters', entityType: ENTITY_TYPE.POPUP })
      onWidgetUpdate({ updatedWidget: { ...widget, dataConfig: { ...widget.dataConfig, filters } } })
    }

    const onSortChange = (sort) => {
      onWidgetUpdate({ updatedWidget: { ...widget, drillDownConfig: { ...widget.drillDownConfig, sort: sortStringFromArray(sort) } } })
    }

    const handleExportToCsv = (...args) => {
      Analytics.clickOnExportCSV({ dashboardName, dashboardSystemKey, widgetTitle: widget?.displayConfig.label })
      exportToCsv(...args)
    }

    const hasExtraFilters = extraFilters && extraFilters.length > 0
    const allowTableEdits = isEditMode && !hasExtraFilters
    const disableFilterEditTooltip = !isOrgPlanAllowToUpdateWidgetConfig
      ? {
        tooltipFiltersMessage: getPlanForFeatureTooltipLabel(FEATURES.DASHBOARDS.FEATURES.UPDATE_WIDGET_CONFIG),
        tooltipColumnsMessage: getPlanForFeatureTooltipLabel(FEATURES.DASHBOARDS.FEATURES.UPDATE_WIDGET_CONFIG)
      }
      : hasExtraFilters ? {
        tooltipFiltersMessage: 'Additional filters are applied on top of the dashboard filters',
        tooltipColumnsMessage: 'Additional filters are applied on top of the dashboard columns'
      } : {
        tooltipFiltersMessage: 'Enter edit mode to update filters',
        tooltipColumnsMessage: 'Enter edit mode to update columns'
      }

    return (
      <Table
        tableKey={getWidgetTableKey(widget)}
        data={isLoading ? EMPTY_ARRAY : rows}
        columns={columns}
        loading={isLoading}
        filterable
        filtersOptions={filtersOptions}
        filterOptionsValuesPerKey={fieldValues}
        configurableColumnsOptions={configurableColumnsOptions}
        configurableColumns
        disableFilterEdit={allowTableEdits ? undefined : disableFilterEditTooltip}
        draggable={allowTableEdits}
        sortable={allowTableEdits}
        onColumnSelectionChange={onColumnSelectionChange}
        onColumnOrderChange={onColumnSelectionChange}
        onSortedChangeCB={onSortChange}
        fetchFieldValues={fetchFieldValues}
        manual
        exportable
        exportFunction={handleExportToCsv}
        exportRowLimit={getExportRowLimit(widget.entityType)}
        onFilterChange={onFilterChange}
        fetchData={fetchData}
        totalCount={totalResults}
        scrollObjectId='contentArea'
        loadingMore={loadingMore}
      />
    )
  }

  switch (widget.entityType) {
    case ENTITY_TYPES.USERS:
      return <UserDataPopup renderFunc={renderFunc} widget={widget} fields={widget?.drillDownConfig?.fields} />
    case ENTITY_TYPES.APPS:
      return <AppDataPopup renderFunc={renderFunc} widget={widget} fields={widget?.drillDownConfig?.fields} />
    case ENTITY_TYPES.CONTRACTS:
      return <ContractDataPopup renderFunc={renderFunc} widget={widget} resources={widgetData?.drillDownData?.resources} fields={widget?.drillDownConfig?.fields} />
    case ENTITY_TYPES.TRANSACTIONS:
      return <TransactionDataPopup renderFunc={renderFunc} widget={widget} resources={widgetData?.drillDownData?.resources} fields={widget?.drillDownConfig?.fields} />
    case ENTITY_TYPES.APP_USERS:
      return <AppUserDataPopup renderFunc={renderFunc} widget={widget} resources={widgetData?.drillDownData?.resources} fields={widget?.drillDownConfig?.fields} />
    default:
      return <div />
  }
}

export default DynamicWidgetDataPopupContent
