import styled from 'styled-components'
import { body } from '@shared/style/textsStyled'
import colors, { baseColors } from '@shared/style/colors'
import { fontSize, fontWeight, margin } from '@shared/style/sizes'

const VALUE_MARGIN = '8px'

export const Placeholder = { paddingTop: '20px', height: '60px', width: '100%' }
export const UserDetails = { padding: 0, margin: 'auto 0' }

export const Main = styled.div`
  position: relative;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  background: ${colors.white};
  color: ${baseColors.grey1};
  ${body}
`

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
`

export const Header = styled.header`
  color: ${colors.black};
  font-size: ${fontSize.medium};
  line-height: 22px;
  margin-right: ${margin.smallMedium};
  font-weight: ${fontWeight.semiBold};
`

export const BodyWrapper = styled.div`
  display: flex;
  padding: 0 20px;
  vertical-align: middle;
  line-height: 40px;
  width: 100%;
  align-items: center;
  >div {
    line-height: 40px;
    vertical-align: middle;
    gap: 10px;
  }
`

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const InfoLineWrapper = styled.div`
  display: flex;
  white-space: pre;
  height: 30px;
  align-items: center;
  margin-top: 20px;
`

export const EmailWrapper = styled.div`
  display: flex;
  white-space: pre;
  height: 30px;
  align-items: center;
  margin-bottom: 20px;
`

export const ActionName = styled.span`
  margin-right: auto;
`

export const EmailTo = styled.span`
  overflow: auto;
`

export const PolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre;
  height: 100%;
`

export const PolicyHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`

export const PolicyInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

export const CreatedBy = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 80px;
`

export const Triggered = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 80px;
`

export const Active = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.span`
  color: ${colors.grey1};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.bold};
  margin-right: ${VALUE_MARGIN};
  line-height: 1;
`

export const TableTitle = styled(Title)`
  margin-bottom: 8px;
`
