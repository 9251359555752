import React from 'react'
import { Button, ButtonType, Subtitle2, Tooltip } from '@toriihq/design-system'
import { LeftPadding, ActionCell } from './style'
import { TotalCostCell } from './TotalCostCell'
import { FinalAnnualUnitCostCell } from './FinalAnnualUnitCostCell'

interface ColumnConfigProps {
  onDelete: (index: number) => void
  currencySymbol: string
  hasLicenseCostReadAccess: boolean
  hasContractsWriteAccessOrAppOwner: boolean
  columnsConfiguration?: Record<string, boolean>
}

export const getLineItemColumns = ({ onDelete, currencySymbol, hasLicenseCostReadAccess, hasContractsWriteAccessOrAppOwner, columnsConfiguration }: ColumnConfigProps) => [
  {
    accessor: 'id',
    show: false
  },
  {
    Header: <Subtitle2><LeftPadding>Description</LeftPadding></Subtitle2>,
    accessor: 'description',
    minWidth: 200,
    fixed: 'left',
    sortable: false,
    show: Boolean(columnsConfiguration?.description)
  },
  // Temporarily removed for first phase
  // {
  //   Header: <Subtitle2>Match to license</Subtitle2>,
  //   accessor: 'idLicense',
  //   minWidth: 200,
  //   sortable: false,
  //   show: hasLicenseCostReadAccess && Boolean(columnsConfiguration?.idLicense)
  // },
  {
    Header: <Subtitle2>Quantity</Subtitle2>,
    accessor: 'quantity',
    minWidth: 100,
    sortable: false,
    show: Boolean(columnsConfiguration?.quantity)
  },
  {
    Header: <Subtitle2>Unit cost</Subtitle2>,
    accessor: 'pricePerUnit',
    minWidth: 150,
    sortable: false,
    show: Boolean(columnsConfiguration?.pricePerUnit)
  },
  {
    Header: <Subtitle2>Discount</Subtitle2>,
    accessor: 'discount',
    minWidth: 100,
    sortable: false,
    show: Boolean(columnsConfiguration?.discount)
  },
  {
    id: 'totalCost',
    Header: <Subtitle2>Total cost</Subtitle2>,
    Cell: ({ index }) => (
      <TotalCostCell index={index} currencySymbol={currencySymbol} />
    ),
    minWidth: 150,
    sortable: false,
    show: Boolean(columnsConfiguration?.totalCost)
  },
  {
    id: 'finalAnnualUnitCost',
    Header: <Subtitle2>Final Unit Cost</Subtitle2>,
    Cell: ({ index }) => (
      <FinalAnnualUnitCostCell index={index} currencySymbol={currencySymbol} />
    ),
    minWidth: 170,
    sortable: false,
    show: Boolean(columnsConfiguration?.finalAnnualUnitCost)
  },
  {
    Header: <Subtitle2>Payment term</Subtitle2>,
    accessor: 'paymentTerm',
    minWidth: 200,
    sortable: false,
    show: Boolean(columnsConfiguration?.paymentTerm)
  },
  {
    Header: <Subtitle2>Start date</Subtitle2>,
    accessor: 'startDate',
    minWidth: 200,
    sortable: false,
    show: Boolean(columnsConfiguration?.startDate)
  },
  {
    Header: <Subtitle2>End date</Subtitle2>,
    accessor: 'endDate',
    minWidth: 200,
    sortable: false,
    show: Boolean(columnsConfiguration?.endDate)
  },
  {
    Header: <Subtitle2>Tax</Subtitle2>,
    accessor: 'tax',
    minWidth: 150,
    sortable: false,
    show: Boolean(columnsConfiguration?.tax)
  },
  {
    Header: '',
    accessor: '',
    Cell: ({ index }) => (
      <ActionCell>
        <Tooltip label='Delete'>
          <Button onClick={() => onDelete(index)} icon='Trash' disabled={!hasContractsWriteAccessOrAppOwner} type={ButtonType.compact} />
        </Tooltip>
      </ActionCell>
    ),
    fixed: 'right',
    minWidth: 50,
    sortable: false
  }
]
