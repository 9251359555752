import { css } from 'glamor'

export const revokeAccessInfoList = css({
  padding: '10px 14px',
  margin: '0px 14px'
})

export const provideAccessInfoList = css({
  padding: '0px 24px'
})

export const content = css({
  padding: '24px'
})

export const emailItem = css({
  display: 'flex',
  flexWrap: 'wrap'
})
