import UserDetails from '@components/userDetails'
import { Icon, Tooltip } from '@toriihq/design-system'
import * as Style from '@components/appUsers/styles'
import UsageIcon from '@components/usageIcon'
import SourceIcon from '@components/sourceIcon'
import userToolTip from '@shared/userToolTip'
import { getDisplayName } from '@lenses/users'
import pluralize from 'pluralize'
import uniq from 'lodash/uniq'
import startCase from 'lodash/startCase'
import getColumnByFieldType from '@components/table/columnFieldTypes'
import React from 'react'
import LastVisitTimePopover from '@components/appUsers/lastVisitTimePopover'
import AppDetails from '@components/appDetails'
import capitalize from 'lodash/capitalize'

export const getColumns = ({ tableInfo, appName, appsById = {}, fieldIdentifier = 'key', includeAppNameColumn = false, includeNewHasLicenseColumn = false }) => {
  const { columnsConfiguration, dynamicColumnsInfo } = tableInfo

  const baseColumns = [
    {
      Header: 'User',
      id: 'fullName',
      accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
      minWidth: 220,
      show: Boolean(columnsConfiguration.fullName),
      Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl, lifecycleStatus, isDeletedInIdentitySources } }) => (
        <UserDetails
          firstName={firstName}
          lastName={lastName}
          email={email}
          idUser={idUser}
          isExternal={isExternal}
          photoUrl={photoUrl}
          lifecycleStatus={lifecycleStatus}
          isDeletedInIdentitySources={isDeletedInIdentitySources}
          showPastUserBadge
        />
      )
    },
    {
      id: 'originEmail',
      Header: 'Email',
      accessor: 'originEmail',
      Cell: ({ row: { originEmail } }) => originEmail,
      show: Boolean(columnsConfiguration['originEmail'])
    },
    {
      Header: (
        <Tooltip
          label={<Style.UsageHeaderTooltipLabel>Usage is based on visits {appName ? `to ${appName}` : ''} in the last 30 days</Style.UsageHeaderTooltipLabel>}
        >
          Usage
        </Tooltip>
      ),
      textHeader: 'Usage',
      accessor: 'score',
      style: { display: 'flex', alignItems: 'center' },
      Cell: ({ value: score, row: { lastVisitTime } }) => <UsageIcon isUserUsage score={score} lastVisitTime={lastVisitTime} />,
      show: Boolean(columnsConfiguration.score)
    },
    {
      Header: 'Account',
      accessor: 'appAccountName',
      Cell: ({ row: { appAccountName } }) => appAccountName || '-',
      show: Boolean(columnsConfiguration.appAccountName)
    },
    {
      Header: 'Last used date',
      accessor: 'lastVisitTime',
      Cell: (props) => {
        const lastVisitTime = props.value
        return (
          <LastVisitTimePopover
            key={props.original.idUser}
            lastVisitTime={lastVisitTime}
            idOrg={props.original.idOrg}
            idApp={props.original.idApp}
            idUser={props.original.idUser}
            appName={props.original.appName}
          />
        )
      },
      minWidth: 200,
      show: Boolean(columnsConfiguration.lastVisitTime)
    },
    {
      Header: 'Sources',
      accessor: 'sources',
      Cell: ({ row: { firstName, lastName, sources, email }, original: { appName } }) => (
        <div>
          {(sources || []).map(source => (<SourceIcon key={source} sourceType={source} tooltipText={userToolTip({ displayName: getDisplayName({ firstName, lastName, email }), appName, source })} />))}
        </div>
      ),
      show: Boolean(columnsConfiguration.sources)
    },
    {
      Header: 'License source',
      accessor: 'licenses.source',
      Cell: ({ row: { licenses = [], firstName, lastName, email }, original: { appName } }) => {
        const getTooltipText = ({ firstName, lastName, email, appName, source }) => {
          const displayName = getDisplayName({ firstName, lastName, email })
          const LICENSE_SOURCE_MANUAL_TYPE = 'manual'
          if (source === LICENSE_SOURCE_MANUAL_TYPE) {
            return `${displayName}'s ${pluralize('license', licenses.length)} ${pluralize('was', licenses.length)} imported via a file upload`
          } else {
            return `${displayName}'s ${pluralize('license', licenses.length)} ${pluralize('was', licenses.length)} synced from ${appName}`
          }
        }

        const uniqueLicenseSources = uniq(licenses.map(license => license.source))

        return <div>
          {uniqueLicenseSources.map(source => (<SourceIcon key={source} sourceType={source} tooltipText={getTooltipText({ firstName, lastName, email, appName, source })} />))}
        </div>
      },
      show: Boolean(columnsConfiguration['licenses.source']),
      sortable: false
    },
    {
      Header: 'Status in app',
      accessor: 'externalStatus',
      Cell: ({ value: status }) => status || '-',
      show: Boolean(columnsConfiguration.externalStatus)
    },
    {
      Header: 'Is past user',
      accessor: 'isDeletedInIdentitySources',
      Cell: ({ value: isDeletedInIdentitySources }) => isDeletedInIdentitySources ? 'Yes' : 'No',
      show: Boolean(columnsConfiguration.isDeletedInIdentitySources)
    },
    {
      Header: 'Role',
      id: 'role',
      accessor: ({ role }) => {
        const roleSplit = (role || '-').split(',')
        return roleSplit.map(role => startCase(role).replace('_', ' ')).join(', ')
      },
      show: Boolean(columnsConfiguration.role)
    },
    {
      Header: 'User type',
      accessor: 'userType',
      Cell: ({ row: { userType } }) => userType || '-',
      show: Boolean(columnsConfiguration.userType)
    },
    {
      Header: 'Torii calculated status',
      accessor: 'status',
      Cell: ({ value: status }) => status ? capitalize(status) : '-',
      show: Boolean(columnsConfiguration.status)
    }
  ]

  const appNameColumn = includeAppNameColumn ? [{
    Header: 'App name',
    accessor: 'appName',
    minWidth: 200,
    maxWidth: 300,
    textHeader: 'Application Name',
    Cell: ({ original: { idApp } }) => {
      const app = appsById[idApp]
      return <AppDetails {...app} component='app users' />
    },
    show: Boolean(columnsConfiguration.appName),
    sortMethod: (apNameA, appNameB) => apNameA.localeCompare(appNameB)
  }] : []

  // The two license column implementations exist to handle different table configurations:
  // 1. When includeNewHasLicenseColumn is true:
  //    Used for metadata and widgets where preDefinedColumnsMapping is not used
  //    - 'hasLicense' and 'licenses.name' are used directly as column identifiers
  // 2. When includeNewHasLicenseColumn is false:
  //    Used for the main appUsersTable which uses preDefinedColumnsMapping
  //    - 'licenses.name' maps to 'Has license'
  //    - 'licenseName' maps to 'Licenses'
  const licensesColumns = includeNewHasLicenseColumn ? [
    {
      Header: 'Has license',
      accessor: 'hasLicense',
      style: { display: 'flex', alignItems: 'center' },
      Cell: ({ row: { licenses = [], hasLicense = false } }) => {
        if (!hasLicense) {
          return '-'
        }
        return <Tooltip
          label={licenses.map(license => <div key={license.type}>{license.name}</div>)}
          placement='top'>
          <Icon name='CheckCircleFill' color='interactive' />
        </Tooltip>
      },
      show: Boolean(columnsConfiguration['hasLicense'])
    },
    {
      Header: 'Licenses',
      accessor: 'licenses.name',
      Cell: ({ row: { licenses = [] } }) => {
        if (!licenses.length) {
          return '-'
        }
        return licenses.map(license => <div key={license.type}>{license.name}</div>)
      },
      show: Boolean(columnsConfiguration['licenses.name']),
      sortable: false
    }
  ] : [
    {
      Header: 'Has license',
      idForColumnsOrder: 'licenses.name',
      accessor: 'licenses',
      style: { display: 'flex', alignItems: 'center' },
      Cell: ({ row: { licenses = [] } }) => {
        if (!licenses.length) {
          return '-'
        }
        return <Tooltip
          label={licenses.map(license => <div key={license.type}>{license.name}</div>)}
          placement='top'>
          <Icon name='CheckCircleFill' color='interactive' />
        </Tooltip>
      },
      show: Boolean(columnsConfiguration['licenses.name'])
    },
    {
      id: 'licenseName',
      Header: 'Licenses',
      Cell: ({ row: { licenses = [] } }) => {
        if (!licenses.length) {
          return '-'
        }
        return licenses.map(license => <div key={license.type}>{license.name}</div>)
      },
      show: Boolean(columnsConfiguration['licenseName'])
    }
  ]

  const hiddenColumns = [
    { accessor: 'reason', show: false },
    { accessor: 'firstName', show: false },
    { accessor: 'lastName', show: false },
    { accessor: 'imageUrl', show: false },
    { accessor: 'idUser', show: false },
    { accessor: 'email', show: false },
    { accessor: 'isExternal', show: false },
    { accessor: 'photoUrl', show: false },
    { accessor: 'docId', show: false },
    { accessor: 'lifecycleStatus', show: false }
  ]

  if (includeNewHasLicenseColumn) {
    hiddenColumns.push({ accessor: 'licenses', show: false })
  }

  return baseColumns
    .concat(appNameColumn)
    .concat(licensesColumns)
    .concat(hiddenColumns)
    .concat(getColumnByFieldType({
      columnsInfo: dynamicColumnsInfo,
      fieldIdentifier
    }))
}
