import React from 'react'
import PropTypes from 'prop-types'
import {
  Icon,
  Tooltip,
  Spacer,
  Button,
  ButtonType,
  ButtonSize,
  Tag,
  TagSize,
  TagTheme,
  Link,
  Divider
} from '@toriihq/design-system'
import SimilarApps from '../similarApps'
import Placeholder from '../placeholder'
import { getFullUrl } from '@shared/utils'
import OverflowTooltip from '@components/overflowTooltip'
import { AppCompliance } from '@components/appAboutBox/appCompliance'
import SelectAppCategory from '@components/selectAppCategory'
import { SCOPES } from '@root/constants'
import { getAppOwnerScopeByIdOrgAndIdApp } from '@lenses/scopes'
import * as Style from './style'

const NUMBER_OF_TAGS_TO_SHOW = 3

class AppAboutBox extends React.Component {
  state = {
    visibleTags: NUMBER_OF_TAGS_TO_SHOW
  }

  componentDidMount () {
    this.fetchData(this.props)
  }

  componentDidUpdate (prevProps) {
    const { idOrg, idApp, isAppComplianceEnabledInPlan } = this.props

    if (isAppComplianceEnabledInPlan !== prevProps.isAppComplianceEnabledInPlan || idApp !== prevProps.idApp || idOrg !== prevProps.idOrg) {
      this.fetchData(this.props)
    }
  }

  fetchData (props) {
    const { idOrg, getAppCompliance, idApp, isAppComplianceEnabledInPlan } = props
    isAppComplianceEnabledInPlan && getAppCompliance({ idOrg, idApp })
  }

  toggleShowMore = (showMore) => {
    this.setState({
      visibleTags: showMore ? this.state.visibleTags + 100 : NUMBER_OF_TAGS_TO_SHOW
    })
  }

  renderTags (tags) {
    const tagsToShow = tags.slice(0, this.state.visibleTags)
    const restTagsNumber = tags.length - NUMBER_OF_TAGS_TO_SHOW
    const shouldShowLinks = restTagsNumber > 0
    const shouldShowMore = this.state.visibleTags === NUMBER_OF_TAGS_TO_SHOW

    return (
      <Style.TagsContainer>
        {tagsToShow.map(tag => <Tag key={tag.name} color='grey' theme={TagTheme.Light} size={TagSize.Medium}><OverflowTooltip label={tag.name}>{tag.name}</OverflowTooltip></Tag>)}
        {shouldShowLinks &&
          (<Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.toggleShowMore(shouldShowMore)} label={shouldShowMore ? `+${restTagsNumber}` : 'Show less'} />)
        }
      </Style.TagsContainer>
    )
  }

  render () {
    const { app, loggedInUser, similarApps, loading, overrideStyle, appComplianceData, isAppComplianceEnabledInPlan, category, idApp, idOrg } = this.props
    const { tags = [] } = app
    return (
      <Style.Container style={overrideStyle}>
        <Style.Header>
          <div>ABOUT</div>
        </Style.Header>

        <Placeholder loading={loading} rows={10} style={{ maxWidth: '80%', marginTop: '10px' }}>
          <Style.Description>
            {app.description}
          </Style.Description>
          {app.category && (
            <Style.DataLine>
              <Spacer right={'space-100'}>
                <Tooltip placement='top' label='Category'>
                  <Icon name='Category' color='secondary' />
                </Tooltip>
              </Spacer>
              {category ? (
                <SelectAppCategory
                  value={category.appValues[0]}
                  idApp={idApp}
                  idOrg={idOrg}
                  selectWidth={200}
                  page='Application -> Overview'
                  scopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE, getAppOwnerScopeByIdOrgAndIdApp(idOrg, idApp)]}
                />) : null}
            </Style.DataLine>
          )}
          {app.vendor && (
            <Style.DataLine>
              <Spacer right={'space-100'}>
                <Tooltip placement='top' label='Vendor'>
                  <Icon name='Company' color='secondary' />
                </Tooltip>
              </Spacer>
              <Style.SectionText> {app.vendor} </Style.SectionText>
            </Style.DataLine>
          )}

          <Style.DataLine>
            <Spacer right={'space-100'}>
              <Tooltip placement='top' label='Website'>
                <Icon name='World' color='secondary' />
              </Tooltip>
            </Spacer>
            <Link href={getFullUrl(app.url)} target='_blank'>{app.url}</Link>
          </Style.DataLine>
          {tags.length > 0 &&
            <div>
              <Divider orientation={'Vertical'} />
              <Style.Tags>
                <Style.TagsHeader>
                  <Spacer right={'space-100'}>
                    <Icon name='Tags' color='secondary' />
                  </Spacer>
                  <Style.SectionText>TAGS</Style.SectionText>
                </Style.TagsHeader>
                <div> {this.renderTags(tags)} </div>
              </Style.Tags>
            </div>
          }
          <Style.DividerContainer>
            <Divider orientation={'Vertical'} />
            <AppCompliance data={appComplianceData} appName={app.name} loggedInUserEmail={loggedInUser.email} isAppComplianceEnabledInPlan={isAppComplianceEnabledInPlan} />
          </Style.DividerContainer>
          {similarApps.length > 0 &&
            <Style.DividerContainer>
              <Divider orientation={'Vertical'} />
              <Style.Similar>
                <SimilarApps idApp={app.id} />
              </Style.Similar>
            </Style.DividerContainer>
          }
        </Placeholder>
      </Style.Container>
    )
  }
}

AppAboutBox.propTypes = {
  loading: PropTypes.bool.isRequired,
  app: PropTypes.object.isRequired,
  similarApps: PropTypes.array,
  tags: PropTypes.array
}

AppAboutBox.defaultProps = {
  tags: [],
  similarApps: []
}

export default AppAboutBox
