import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAppCatalogSettings, getIdOrg, getOrgNickName, isOrgLoading as isOrgLoadingSelector } from '@selectors/org'
import { Container, ToggleContainer } from './style'
import EnableFor from '../enableFor'
import ToriiToggle from '@components/toriiToggle'
import { SCOPES } from '@root/constants'
import { updateAppCatalogSettings } from '@actions/'
import copy from 'copy-to-clipboard'
import Analytics from '@root/helpers/analytics'
import Placeholder from '../placeholder'
import { Tooltip, Button, ButtonType, Link, Stack } from '@toriihq/design-system'
import config from '@root/config'

const AppCatalogHeader = () => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)
  const orgNickName = useSelector(getOrgNickName)
  const { isCatalogEnabledForMembers } = useSelector(getAppCatalogSettings) || {}
  const isOrgLoading = useSelector(isOrgLoadingSelector)

  const [isLinkCopied, setIsLinkCopied] = useState(false)

  const copyToClipboard = (link) => {
    copy(link)
    setIsLinkCopied(true)
    setTimeout(() => setIsLinkCopied(false), 3000)
    Analytics.track('Click on copy-link-button')
  }

  const reportClick = () => {
    Analytics.track('Click on app-catalog-link')
  }

  const onToggle = () => {
    dispatch(updateAppCatalogSettings({ idOrg, isCatalogEnabledForMembers: !isCatalogEnabledForMembers }))
    Analytics.track('Enable app-catalog', {
      State: Number(!isCatalogEnabledForMembers)
    })
  }

  const catalogUrl = `${config.catalogBaseUrl}/${orgNickName}`
  return (
    <Placeholder loading={isOrgLoading}>
      <Container>
        <Stack direction='row' align='center' gap='space-400'>
          <Stack direction='row' align='center' gap='space-100'>
            <span>Catalog Link:</span>
            <Link href={catalogUrl} target='_blank' onClick={reportClick}>{catalogUrl}</Link>
            <Tooltip label={isLinkCopied ? 'Link copied' : 'Copy link'}>
              <Button type={ButtonType.compact} icon='Copy' onClick={() => !isLinkCopied && copyToClipboard(catalogUrl)} />
            </Tooltip>
          </Stack>
          <ToggleContainer>
            <div>Enable for all employees</div>
            <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
              <ToriiToggle
                checked={isCatalogEnabledForMembers}
                onToggle={onToggle}
                id={'enable-catalog-for-all-toggle'}
                data-testid='enable-catalog-for-all-toggle'
              />
            </EnableFor>
          </ToggleContainer>
        </Stack>
      </Container>
    </Placeholder>
  )
}

export default AppCatalogHeader
