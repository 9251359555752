import { useEffect } from 'react'
import { AdvancedCard } from '@toriihq/design-system'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getDashboardsData } from '@selectors/dashboards'
import { DynamicWidgetProps } from '@components/dashboard/widgets/types'
import { getEntityDataForWidget } from '@actions/dashboards'
import { EMPTY_OBJECT, ITEMS_PER_PAGE } from '@root/constants'
import WidgetEmptyState from '../../shared/emptyState'
import { getTableWidgetTableKey } from '@components/dashboard/widgets/shared'
import Table from '@components/table'
import UserDataPopup from '@components/dashboard/dataPopups/dynamicDataPopup/userDataPopup'
import AppDataPopup from '@components/dashboard/dataPopups/dynamicDataPopup/appDataPopup'
import AppUsersPopup from '@components/dashboard/dataPopups/dynamicDataPopup/appUserDataPopup'
import { sortStringFromArray } from '@shared/utils'
import { ENTITY_TYPES } from '@reducers/dashboards/types'
import debounce from 'lodash/debounce'
import * as Style from './style'
import uniq from 'lodash/uniq'
import { USER_REQUIRED_FIELDS } from '@components/dashboard/dataPopups/dynamicDataPopup'
import ContractDataPopup from '@root/components/dashboard/dataPopups/dynamicDataPopup/contractDataPopup'
import TransactionDataPopup from '@components/dashboard/dataPopups/dynamicDataPopup/transactionDataPopup'

const TableWidget = (props: DynamicWidgetProps) => {
  const dispatch = useDispatch()
  const { idDashboard, widget, isSelected, primaryButton, isWidgetMenuOpen, isEditMode, onWidgetUpdate } = props

  const { id: idOrg } = useSelector(getCurrentOrg)
  const dashboardsData = useSelector(getDashboardsData)
  const dashboardData = dashboardsData[idDashboard]
  const widgetsDataById = dashboardData?.widgetsData || EMPTY_OBJECT
  const widgetData = widgetsDataById[widget.id]
  const { loading, rows = [], total } = widgetData?.data ?? EMPTY_OBJECT
  const isLoading = loading && rows.length === 0

  const fetchData = debounce(({ reset = false } = {}): void => {
    dispatch(getEntityDataForWidget({
      entityType: widget.entityType,
      idOrg,
      idDashboard,
      displayConfig: widget.displayConfig,
      idWidget: widget.id,
      filters: widget.dataConfig.filters,
      fields: widget.entityType === ENTITY_TYPES.USERS ? uniq([...USER_REQUIRED_FIELDS, ...widget.displayConfig.fields ?? []]) : undefined,
      sort: widget.displayConfig.sort,
      reset,
      offset: reset ? 0 : rows.length,
      limit: ITEMS_PER_PAGE
    }))
  }, 250)

  useEffect(() => {
    if (idOrg) {
      fetchData({ reset: true })
    }
    // We want this to run only on the first render and when the filters/sort changes, ignoring the lint error since we cannot use useCallack for fetchData since it causes re-fetching loops of data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget.dataConfig.filters, widget.displayConfig.sort, widget.displayConfig.fields])

  const onSortChange = (sort) => {
    onWidgetUpdate({ updatedWidget: { ...widget, displayConfig: { ...widget.displayConfig, sort: [sortStringFromArray(sort)] } } })
  }

  const onColumnSelectionChange = (fields) => {
    onWidgetUpdate({ updatedWidget: { ...widget, displayConfig: { ...widget.displayConfig, fields } } })
  }

  const renderFunc = ({ columns, configurableColumnsOptions, loadingMetadata }) => {
    if (loadingMetadata) {
      return null
    }

    const bodyElementId = `tableWidgetWrapper-${widget.id}`

    return (<AdvancedCard
      title={widget?.displayConfig?.label}
      hoverable
      selected={isSelected}
      primaryButton={primaryButton}
      showPrimaryButton={isWidgetMenuOpen}
      bodyElementId={bodyElementId}
    >

      {isLoading || rows.length > 0 ? <Table
        tableKey={getTableWidgetTableKey(widget)}
        data={rows}
        columns={columns}
        loading={isLoading}
        draggable={isEditMode}
        sortable={isEditMode}
        onColumnOrderChange={onColumnSelectionChange}
        configurableColumnsOptions={configurableColumnsOptions}
        onSortedChangeCB={onSortChange}
        manual
        fetchData={fetchData}
        totalCount={total}
        scrollObjectId={bodyElementId}
        loadingMore={loading}
        style={Style.TableStyle}
      />
        : <WidgetEmptyState />
      }
    </AdvancedCard>)
  }

  switch (widget.entityType) {
    case ENTITY_TYPES.USERS:
      return <UserDataPopup renderFunc={renderFunc} widget={widget} resources={widgetData?.data?.resources} fields={widget?.displayConfig?.fields!} />
    case ENTITY_TYPES.APPS:
      return <AppDataPopup renderFunc={renderFunc} widget={widget} resources={widgetData?.data?.resources} fields={widget?.displayConfig?.fields!} />
    case ENTITY_TYPES.CONTRACTS:
      return <ContractDataPopup renderFunc={renderFunc} widget={widget} resources={widgetData?.data?.resources} fields={widget?.displayConfig?.fields!} />
    case ENTITY_TYPES.TRANSACTIONS:
      return <TransactionDataPopup renderFunc={renderFunc} widget={widget} resources={widgetData?.data?.resources} fields={widget?.displayConfig?.fields!} />
    case ENTITY_TYPES.APP_USERS:
      return <AppUsersPopup renderFunc={renderFunc} widget={widget} resources={widgetData?.data?.resources} fields={widget?.displayConfig?.fields!} />
    default:
      return <div />
  }
}

export default TableWidget
