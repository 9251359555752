import { fontSize, fontWeight } from '@root/shared/style/sizes'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.normal};
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  };
`

export const ToggleContainer = styled.div`
  display: inline-flex;
  gap: 10px;
  align-items: flex-end
`
