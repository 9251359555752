import styled from 'styled-components'
import { insideTableCellsClass, insideTableRowsClass } from '@shared/style/mixins'

export const Wrapper = styled.div`
  min-height: 500px;
`

export const ActionsContainer = styled.div<{isSmallScreen : boolean}>`
  text-align: right;
  ${({ isSmallScreen }) => !isSmallScreen && `
    opacity: 0;
  `};
  transition: opacity .2s;
  ${insideTableCellsClass} {
    text-overflow: unset !important
  };
  ${insideTableRowsClass} {
    opacity: 1;
  };
`
