import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import { TABLES, formFieldTypes } from '@root/constants'
import { getWidgetTableKey } from '@components/dashboard/widgets/shared'
import {
  getContractFilterOptionsValuesPerKey,
  getContractsFieldsBySystemKey,
  getContractsTablesInfo,
  getContractStatusOptions,
  getContractStatusOptionsOrder,
  isContractsMetadataLoading
} from '@selectors/contracts'
import getContractColumns from '@components/contracts/columns'
import { toggleContractPopup } from '@store/actions'
import config from '@root/config'
import { mapSortArray } from '@shared/utils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { DynamicWidgetInternalDataPopupProps } from '../types'
import { useGetEntityTableData } from '../shared.hooks'

const ContractDataPopup = (props: DynamicWidgetInternalDataPopupProps): ReactElement | null => {
  const dispatch = useDispatch()
  const { renderFunc, widget, resources, fields = [] } = props

  const idOrg = useSelector(getIdOrg)
  const contractFieldsById = useSelector(getContractsFieldsBySystemKey)
  const contractStatusOptions = useSelector(getContractStatusOptions)
  const contractStatusOptionsOrder = useSelector(getContractStatusOptionsOrder)
  const contractFilterOptionsValuesPerKey = useSelector(getContractFilterOptionsValuesPerKey)
  const contractTableInfo = useSelector(getContractsTablesInfo)[TABLES.contractsTable.key]
  const loadingMetadata = useSelector(isContractsMetadataLoading)

  const { preDefinedColumnsMapping, configurableColumnsOptions, filtersOptions, customFields, columnsConfiguration } = useGetEntityTableData({ entityType: widget.entityType, fields })

  const [columns, setColumns] = useState<any []>([])

  const { users: usersById = {}, apps: appsById = {}, contracts: contractsById = {} } = resources ?? {}

  const fetchFieldValues = () => {}
  const fieldValues = contractFilterOptionsValuesPerKey
  const contractPreDefinedColumnsMapping = preDefinedColumnsMapping
  const dynamicColumnsInfo = configurableColumnsOptions.filter(a =>
    !contractTableInfo.specialColumnsInfo[a.value] &&
      !contractPreDefinedColumnsMapping[a.value] &&
      columnsConfiguration[a.value]
  ).map(column => ({ systemKey: column.value, key: column.value, name: column.label, ...column, ...contractFieldsById[column.value] }))

  useDeepCompareEffect(() => {
    const onEditContract = (idContract) => {
      dispatch(toggleContractPopup({ isDuplicated: false, idContract, isOpen: true } as any))
    }

    setColumns(getContractColumns({
      contractStatusOptions,
      contractStatusOptionsOrder,
      usersById,
      appsById,
      contractsById,
      onEditContract,
      onDuplicateContract: () => {},
      contractsFields: customFields,
      idOrg,
      idApp: null,
      onDeleteContract: () => {},
      columnsConfiguration,
      dynamicColumnsInfo,
      name: getWidgetTableKey(widget),
      preDefinedColumnsMapping: contractPreDefinedColumnsMapping,
      specialColumnsInfo: contractTableInfo.specialColumnsInfo,
      showActions: false,
      customFieldPropsByType: {
        [formFieldTypes.usersDropdown]: ({ field }) => ({
          disabled: true
        }),
        [formFieldTypes.usersDropdownMulti]: ({ field }) => ({
          disabled: true
        })
      },
      disableContractOwnerField: true
    } as any))
  }, [columnsConfiguration, customFields, contractPreDefinedColumnsMapping, contractStatusOptions, contractStatusOptionsOrder, contractTableInfo.specialColumnsInfo, dispatch, dynamicColumnsInfo, idOrg, widget, usersById, appsById, contractsById])

  const exportToCsv = ({ sort, query, filters }) => {
    const columnsOrder = fields
    const columnsOrdered = [...columns].sort((a, b) => columnsOrder.indexOf(a.id || a.accessor) - columnsOrder.indexOf(b.id || b.accessor))
    const qFields = ['id', 'name', 'app.name']

    const sortParam = `sort=${mapSortArray(sort.map(s => s.id === 'idApp' ? { ...s, id: 'app.name' } : s)).join(',')}`
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${columnsOrdered.filter(col => ((col.forceCSV || col.show || col.show === undefined) && !col.hideFromCSV)).map(col => col.id || col.accessor).join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`
    const qFieldsParam = `qFields=${qFields.join(',')}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/contracts/csv?${sortParam}&${queryParam}&${filtersParam}&${fieldsParam}&${qFieldsParam}`
    const newWindow = window.open(url, '_blank') as any
    newWindow.opener = null
  }

  return renderFunc({ columns, filtersOptions, fieldValues, configurableColumnsOptions, fetchFieldValues, exportToCsv, loadingMetadata })
}

export default ContractDataPopup
