import styled from 'styled-components'

export const TextWithImageAside = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const MainTitle = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle02};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const SecondaryTitle = styled.div`
  margin-top: 16px;
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const SideImage = styled.img`
  width: 120px;
  height: 120px;
  padding: 4.673px 13.578px 6.398px 12.67px;
`
