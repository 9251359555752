import React, { Fragment, ReactElement } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { RendererProps } from '@components/popups/wizardPopup/types'
import { SharedProps, SharedState, WIZARD_STEPS } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/types'
import revokeMerge from '../images/revokeMerge.svg'
import { Styles } from './style'
import { SUPPORT_ARTICLES } from '@root/articles'
import { Body2, ButtonType, Link } from '@toriihq/design-system'
import { MergeUsersReport } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/mergeUsersReportButton'
import { deleteMergeUsersRule } from '@actions/org'
import { useDispatch } from 'react-redux'
import Analytics from '@components/popups/configureMergeUsersRulesPopup/analytics'
import { PopupImage } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/popupImage'

export const RevokeMerge = ({
  sharedProps,
  sharedState,
  onCancel,
  navigateToStep
}: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): ReactElement => {
  const { idOrg } = sharedProps
  const { idMergeUsersRule, selectedRuleIdApp } = sharedState
  const dispatch = useDispatch()

  const onRevokeClick = async () => {
    idMergeUsersRule && await dispatch(deleteMergeUsersRule({
      idOrg,
      idRule: idMergeUsersRule
    }))

    Analytics.onRevokeMergeClick({ idOrg, idApp: selectedRuleIdApp })
    navigateToStep(WIZARD_STEPS.CHANGE_APPLIED)
  }

  return (<Fragment>
    <ToriiPopup.Header header={'Revoke merge'} />
    <ToriiPopup.Content contentAreaStyle={{ padding: '0px 0px 24px', gap: '24px' }}>
      <PopupImage src={revokeMerge} alt='Revoke Merge' />
      <Styles.Container>
        <Styles.TextArea>
          <Styles.TextListHeader>What to expect</Styles.TextListHeader>
          <Styles.TextList>
            <Body2>
              <li>The number of users within Torii will change.</li>
            </Body2>
            <Body2>
              <li>Data on previously merged users will be separated to each user individually (including access to apps,
                licenses, annual cost etc.).
              </li>
            </Body2>
            <Body2>
              <li>Each user will be able to log in to Torii with their independent email, if they had access
                previously.
              </li>
            </Body2>
            <Body2>
              <li>App ownership and similar fields will be updated.</li>
            </Body2>
          </Styles.TextList>
          <Link href={SUPPORT_ARTICLES.MERGE_USERS} target='_blank' onClick={() => Analytics.onLearnMoreClick({ idOrg, buttonLocation: 'popup' })}>
            Learn more
          </Link>
        </Styles.TextArea>
        <MergeUsersReport isRevokeMode />
      </Styles.Container>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      cancelButtonText={'Cancel'}
      cancelButtonAction={onCancel}
      mainButtonText={'Revoke'}
      mainButtonAction={onRevokeClick}
      mainButtonType={ButtonType.destructive}
    />
  </Fragment>)
}
