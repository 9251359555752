import React, { ReactNode } from 'react'
import { Link } from '@toriihq/design-system'
import Analytics from '@helpers/analytics'

type Props = {
  url?: string,
  content: string,
  actionType?: string,
  analyticsOptions?: { isOffboardingAudit?: boolean, appOffboardingState?: string }
}

const TicketLink = ({ url, content, actionType, analyticsOptions = {} }: Props): ReactNode => {
  const reportClick = ({ actionType }: { actionType?: string }): void => {
    const { isOffboardingAudit, appOffboardingState } = analyticsOptions
    const analyticsEvent = isOffboardingAudit ? 'Click on offboarding-ticket-link' : 'Click on ticket-link'

    Analytics.track(analyticsEvent, {
      'Action': actionType,
      'State': appOffboardingState
    })
  }

  if (!url) {
    return content
  }

  return <Link
    target={'_blank'}
    href={url}
    onClick={() => reportClick({ actionType })}
  >
    <strong>{content}</strong>
  </Link>
}

export default TicketLink
