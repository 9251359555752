import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'

export const CSS = {
  headerContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '19px'
  }),
  header: css(texts.headers.small, {
    textTransform: 'uppercase'
  }),
  container: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    padding: '20px'
  }),
  formContainer: css({
    maxWidth: '700px'
  }),
  stepsDescription: css({
    marginBottom: '20px'
  }),
  alertBoxContent: css({
    color: colors.black
  }),
  nextButtonContainer: css({
    display: 'flex',
    justifyContent: 'flex-end'
  })
}
