import styled from 'styled-components'
import { fontWeight } from '@shared/style/sizes'

export const Container = styled.div`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background.primaryActive};
  padding: 20px;
  color: ${({ theme }) => theme.palette.text.primary};
`

export const Icon = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  line-height: 17px;
  text-align: center;
  margin-right: 10px;
  transform: none;
  font-size: 10px;
`

export const Header = styled.div`
  display: flex;
  font-weight: ${fontWeight.bold};
  line-height: 24px;
`

export const Description = styled.div`
  padding-top: 20px;
  padding-bottom: 23px;
  display: flex;
`

export const SectionText = styled.div`
  vertical-align: middle;
  line-height: 25px;
  display: inline-block;
`

export const DataLine = styled.div`
  padding-bottom: 14px;
  display: flex;
  align-items: center;
`

export const Tags = styled.div`
  padding-top: 14px;
`

export const TagsHeader = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  line-height: 20px;
`

export const Similar = styled.div`
  padding-top: 14px;
  display: flex;
`

export const DividerContainer = styled.div`
  padding-top: 14px;
`

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px 6px;
`
