const fixedWidgets = {
  widgets: [
    {
      'id': 1736353313,
      'type': 'fixedWidget',
      'fixedWidgetType': 'usersMap',
      'displayConfig': {
        'label': 'Users map'
      }
    }
  ],
  layout: [
    {
      'h': 6,
      'i': 1736353313,
      'x': 0,
      'y': 43,
      'w': 6
    }
  ]
}

const saasInventoryDashboard = {
  title: 'SaaS Portfolio',
  description: 'Overview of your SaaS portfolio, app breakdown and usage.',
  widgets: [
    {
      'id': 1736343285,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'All apps',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'displayActiveUsersCount',
          'expenses',
          'score'
        ]
      }
    },
    {
      'id': 1736343286,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'type': 'number',
              'label': 'Number of users',
              'value': 'displayActiveUsersCount'
            },
            'value': 10
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Commonly used apps',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'displayActiveUsersCount',
          'expenses',
          'score'
        ]
      }
    },
    {
      'id': 1736343287,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'New app',
              'value': 'isNew'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Newly discovered apps (last 7 days)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'creationTime',
          'displayActiveUsersCount',
          'score',
          'state',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343288,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'type': 'number',
              'label': 'Number of apps used',
              'value': 'activeAppsCount'
            },
            'value': 0
          },
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is External',
              'value': 'isExternal'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'id': 1737626703880,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Avg. apps per user',
        'metric': {
          'func': 'avg',
          'field': {
            'type': 'number',
            'label': 'Number of apps used',
            'systemKey': 'activeAppsCount'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'activeAppsCount:desc'
        ],
        'fields': [
          'fullName',
          'additionalEmails',
          'activeAppsCount',
          'annualCostConverted'
        ]
      }
    },
    {
      'id': 1736343289,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1737649754498,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps by source',
        'groupBy': {
          'size': 5,
          'sort': {
            'order': 'desc',
            'aggFunc': 'total'
          },
          'field': {
            'type': 'dropdownMulti',
            'label': 'Sources',
            'systemKey': 'sources'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'sources',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343290,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps by state',
        'groupBy': {
          'size': 5,
          'sort': {
            'order': 'desc',
            'aggFunc': 'total'
          },
          'field': {
            'type': 'singleLine',
            'label': 'State',
            'systemKey': 'state'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'state:asc'
        ],
        'fields': [
          'name',
          'state',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343291,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1737905370804,
            'op': {
              'type': 'dropdownMulti',
              'label': 'has none of',
              'value': 'noneOf'
            },
            'key': {
              'type': 'dropdownMulti',
              'label': 'Certifications & Compliance',
              'value': 'certificationsAndCompliance'
            },
            'value': [
              {
                'label': 'soc 2',
                'value': 'soc 2'
              },
              {
                'label': 'iso 27001',
                'value': 'iso 27001'
              }
            ]
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps w/o ISO/SOC2 data',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'certificationsAndCompliance',
          'displayActiveUsersCount',
          'score'
        ]
      }
    },
    {
      'id': 1736343292,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1737905669950,
            'op': {
              'type': 'dropdownMulti',
              'label': 'has none of',
              'value': 'noneOf'
            },
            'key': {
              'type': 'dropdownMulti',
              'label': 'Certifications & Compliance',
              'value': 'certificationsAndCompliance'
            },
            'value': [
              {
                'label': 'gdpr',
                'value': 'gdpr'
              }
            ]
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps w/o GDPR data',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'certificationsAndCompliance',
          'displayActiveUsersCount',
          'score'
        ]
      }
    },
    {
      'id': 1736343293,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1737905812210,
            'op': {
              'label': 'is not set',
              'value': 'isNotSet'
            },
            'key': {
              'type': 'user',
              'label': 'Primary App Owner',
              'value': 'primaryOwner'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps without owners',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'creationTime',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343294,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1737628364676,
            'op': {
              'type': 'number',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'number',
              'label': 'Number of used days',
              'value': 'score'
            },
            'value': 0
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Potentially unused apps',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343295,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1737650028499,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Has integration',
              'value': 'hasIntegration'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Potential integrations connected',
        'groupBy': {
          'size': 5,
          'sort': {
            'order': 'desc',
            'aggFunc': 'total'
          },
          'field': {
            'type': 'bool',
            'label': 'Is connected',
            'systemKey': 'isConnected'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'isConnected:desc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'displayActiveUsersCount',
          'expenses',
          'score',
          'isConnected'
        ]
      }
    },
    {
      'id': 1736343310,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1739975797293,
            'op': {
              'type': 'number',
              'label': 'greater than or equals',
              'value': 'gte'
            },
            'key': {
              'type': 'number',
              'label': 'Number of users',
              'value': 'displayActiveUsersCount'
            },
            'value': 10
          },
          {
            'id': 1740056207266,
            'op': {
              'type': 'number',
              'label': 'greater than or equals',
              'value': 'gte'
            },
            'key': {
              'type': 'number',
              'label': 'Number of used days',
              'value': 'score'
            },
            'value': 0
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Top apps by usage',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'score',
            'order': 'desc',
            'aggFunc': 'max'
          },
          'field': {
            'type': 'text',
            'label': 'App name',
            'systemKey': 'name'
          },
          'metric': {
            'func': 'max',
            'field': {
              'type': 'number',
              'label': 'Number of used days',
              'systemKey': 'score'
            }
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'score:desc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'score',
          'displayActiveUsersCount',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343311,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1740309675525,
            'op': {
              'type': 'datePicker',
              'label': 'is on or after',
              'value': 'dayOnOrAfter'
            },
            'key': {
              'type': 'date',
              'label': 'Discovery date',
              'value': 'creationTime'
            },
            'value': '2024-01-01'
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Discovered apps over time',
        'groupBy': {
          'size': 10,
          'sort': {
            'field': 'creationTime',
            'order': 'asc'
          },
          'field': {
            'type': 'date',
            'label': 'Discovery date',
            'systemKey': 'creationTime'
          },
          'metric': {
            'func': 'total'
          },
          'datePeriod': 'quarterly'
        },
        'barOrientation': 'vertical'
      },
      'drillDownConfig': {
        'sort': [
          'creationTime:desc'
        ],
        'fields': [
          'name',
          'creationTime',
          'displayActiveUsersCount',
          'state',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343312,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1739975787721,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'App count by tag',
        'groupBy': {
          'size': 5,
          'sort': {
            'order': 'desc',
            'aggFunc': 'total'
          },
          'field': {
            'type': 'dropdownMulti',
            'label': 'Tags',
            'systemKey': 'tags'
          },
          'metric': {
            'func': 'total'
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'tags',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    }
  ],
  layoutConfig: [
    { h: 2, i: 1736343285, w: 3, x: 0, y: 0 },
    { h: 2, i: 1736343286, w: 3, x: 3, y: 0 },
    { h: 2, i: 1736343287, w: 3, x: 6, y: 0 },
    { h: 2, i: 1736343288, w: 3, x: 9, y: 0 },
    { h: 6, i: 1736343289, w: 6, x: 0, y: 2 },
    { h: 6, i: 1736343290, w: 6, x: 6, y: 2 },
    { h: 2, i: 1736343291, w: 3, x: 0, y: 8 },
    { h: 2, i: 1736343292, w: 3, x: 3, y: 8 },
    { h: 2, i: 1736343293, w: 3, x: 6, y: 8 },
    { h: 2, i: 1736343294, w: 3, x: 9, y: 8 },
    { h: 6, i: 1736343295, w: 6, x: 0, y: 10 },
    { h: 6, i: 1736343310, w: 6, x: 6, y: 10 },
    { h: 6, i: 1736343311, w: 6, x: 0, y: 16 },
    { h: 6, i: 1736343312, w: 6, x: 6, y: 16 }
  ]
}

const costOptimizationDashboard = {
  title: 'Cost Optimization',
  description: 'Analyze expense trends, efficiencies and anomalies to optimize SaaS spend',
  widgets: [
    {
      'id': 1736343320,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'label': 'Transaction date',
              'value': 'transactionDate'
            },
            'value': {
              'type': {
                'label': 'days ago',
                'value': 'ago'
              },
              'number': '365'
            }
          },
          {
            'id': 1740580062479,
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'Matching status',
              'value': 'mappingStatus'
            },
            'value': {
              'label': 'Matched',
              'value': 'mapped'
            }
          }
        ]
      },
      'entityType': 'transactions',
      'displayConfig': {
        'label': 'Matched expenses (12 months)',
        'metric': {
          'func': 'sum',
          'field': {
            'type': 'currency',
            'label': 'Amount',
            'systemKey': 'amount'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'transactionDate:desc'
        ],
        'fields': [
          'transactionDate',
          'amount',
          'description',
          'toriiSource',
          'appName'
        ]
      }
    },
    {
      'id': 1736343321,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'label': 'Transaction date',
              'value': 'transactionDate'
            },
            'value': {
              'type': {
                'label': 'days ago',
                'value': 'ago'
              },
              'number': '365'
            }
          },
          {
            'id': 1740998642981,
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'Matching status',
              'value': 'mappingStatus'
            },
            'value': {
              'label': 'Potential match',
              'value': 'recommend_map'
            }
          }
        ]
      },
      'entityType': 'transactions',
      'displayConfig': {
        'label': 'Potential expense matches',
        'metric': {
          'func': 'sum',
          'field': {
            'type': 'currency',
            'label': 'Amount',
            'systemKey': 'amount'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'transactionDate:desc'
        ],
        'fields': [
          'transactionDate',
          'amount',
          'description',
          'toriiSource',
          'mappingStatus',
          'appName'
        ]
      }
    },
    {
      'id': 1736343322,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'label': 'Transaction date',
              'value': 'transactionDate'
            },
            'value': {
              'type': {
                'label': 'days ago',
                'value': 'ago'
              },
              'number': '30'
            }
          },
          {
            'id': 1740580196650,
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'Matching status',
              'value': 'mappingStatus'
            },
            'value': {
              'label': 'Matched',
              'value': 'mapped'
            }
          }
        ]
      },
      'entityType': 'transactions',
      'displayConfig': {
        'label': 'Recent expenses (30 days)',
        'metric': {
          'func': 'sum',
          'field': {
            'type': 'currency',
            'label': 'Amount',
            'systemKey': 'amount'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'transactionDate:desc'
        ],
        'fields': [
          'transactionDate',
          'amount',
          'description',
          'toriiSource',
          'appName'
        ]
      }
    },
    {
      'id': 1736343323,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1740580264592,
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'Matching status',
              'value': 'mappingStatus'
            },
            'value': {
              'label': 'Matched',
              'value': 'mapped'
            }
          },
          {
            'id': 1740580388912,
            'op': {
              'type': 'datePicker',
              'label': 'is on or after',
              'value': 'dayOnOrAfter'
            },
            'key': {
              'type': 'date',
              'label': 'Transaction date',
              'value': 'transactionDate'
            },
            'value': '2024-01-01'
          }
        ]
      },
      'entityType': 'transactions',
      'displayConfig': {
        'label': 'Expenses over time',
        'groupBy': {
          'size': 24,
          'sort': {
            'field': 'transactionDate',
            'order': 'asc'
          },
          'field': {
            'type': 'date',
            'label': 'Transaction date',
            'systemKey': 'transactionDate'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Amount',
              'systemKey': 'amount'
            }
          },
          'datePeriod': 'monthly'
        },
        'barOrientation': 'vertical'
      },
      'drillDownConfig': {
        'sort': [
          'transactionDate:desc'
        ],
        'fields': [
          'transactionDate',
          'amount',
          'description',
          'toriiSource',
          'appName'
        ]
      }
    },
    {
      'id': 1736343324,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1740574397933,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'User cost by department',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'annualCostConverted',
            'order': 'desc',
            'aggFunc': 'avg'
          },
          'field': {
            'type': 'singleLine',
            'label': 'Google Workspace Department',
            'systemKey': 'gsuite_department'
          },
          'metric': {
            'func': 'avg',
            'field': {
              'type': 'currency',
              'label': 'Annual cost',
              'systemKey': 'annualCostConverted'
            }
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'annualCostConverted:desc'
        ],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'gsuite_department',
          'gsuite_title',
          'gsuite_userManager'
        ]
      }
    },
    {
      'id': 1736343325,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'App spend by tag',
        'groupBy': {
          'size': 25,
          'sort': {
            'field': 'expenses',
            'order': 'desc',
            'aggFunc': 'sum'
          },
          'field': {
            'type': 'dropdownMulti',
            'label': 'Tags',
            'systemKey': 'tags'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Expenses',
              'systemKey': 'expenses'
            }
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'state',
          'tags',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343326,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1740574543104,
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'name': 'Expenses (last 12 months)',
              'type': 'currency',
              'label': 'Expenses (last 12 months)',
              'value': 'expenses',
              'scopes': [
                'expense:read',
                'app-owner:read'
              ],
              'systemKey': 'expenses'
            },
            'value': 0
          },
          {
            'id': 1740668693985,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'State',
              'value': 'state',
              'systemKey': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps with expenses',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'state',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343327,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1740574562950,
            'op': {
              'type': 'number',
              'label': 'lower than or equals',
              'value': 'lte'
            },
            'key': {
              'name': 'Expenses (last 12 months)',
              'type': 'currency',
              'label': 'Expenses (last 12 months)',
              'value': 'expenses',
              'scopes': [
                'expense:read',
                'app-owner:read'
              ],
              'systemKey': 'expenses'
            },
            'value': 0
          },
          {
            'id': 1740668745001,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'State',
              'value': 'state',
              'systemKey': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps w/o expenses',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343328,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1740574601601,
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'name': 'Expenses (last 12 months)',
              'type': 'currency',
              'label': 'Expenses (last 12 months)',
              'value': 'expenses',
              'scopes': [
                'expense:read',
                'app-owner:read'
              ],
              'systemKey': 'expenses'
            },
            'value': 0
          },
          {
            'id': 1740574608784,
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Closed apps with expenses',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'state',
          'displayActiveUsersCount',
          'score',
          'expenses'
        ]
      }
    },
    {
      'id': 1736343329,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1740574633555,
            'op': {
              'type': 'number',
              'label': 'lower than or equals',
              'value': 'lte'
            },
            'key': {
              'name': 'Number of used days',
              'type': 'number',
              'label': 'Number of used days',
              'value': 'score',
              'systemKey': 'score'
            },
            'value': 3
          },
          {
            'id': 1740574669300,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps with high expenses and low usage',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'expenses',
            'order': 'desc',
            'aggFunc': 'sum'
          },
          'field': {
            'type': 'text',
            'label': 'App name',
            'systemKey': 'name'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Expenses',
              'systemKey': 'expenses'
            }
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'state',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343330,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1740574699285,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'App spend by category',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'expenses',
            'order': 'desc',
            'aggFunc': 'sum'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Category',
            'systemKey': 'category'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Expenses',
              'systemKey': 'expenses'
            }
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'state',
          'category',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736353311,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1739975787721,
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'name': 'Expenses compared to contract value (%)',
              'type': 'number',
              'label': 'Expenses compared to contract value (%)',
              'value': 'expensesComparedToActiveContractValue',
              'scopes': [
                'expense:read',
                'app-owner:read'
              ],
              'systemKey': 'expensesComparedToActiveContractValue'
            },
            'value': 0
          },
          {
            'id': 1742373413535,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'name': 'State',
              'type': 'dropdown',
              'label': 'State',
              'value': 'state',
              'systemKey': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'sort': [
          'expensesComparedToActiveContractValue:desc'
        ],
        'label': 'Apps with expenses exceeding contract value',
        'fields': [
          'name',
          'expensesInActiveContractTerm',
          'activeContractsTotalValue',
          'expensesComparedToActiveContractValue',
          'primaryOwner',
          'activeContractTerm.earliestStartDate',
          'activeContractTerm.latestEndDate'
        ]
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'expensesInActiveContractTerm',
          'activeContractsTotalValue',
          'expensesComparedToActiveContractValue',
          'primaryOwner',
          'activeContractTerm.earliestStartDate',
          'activeContractTerm.latestEndDate'
        ]
      }
    }
  ],
  layoutConfig: [
    {
      'h': 2,
      'i': 1736343320,
      'w': 4,
      'x': 0,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343321,
      'w': 4,
      'x': 0,
      'y': 8
    },
    {
      'h': 2,
      'i': 1736343322,
      'w': 4,
      'x': 4,
      'y': 0
    },
    {
      'h': 6,
      'i': 1736343323,
      'w': 12,
      'x': 0,
      'y': 2
    },
    {
      'h': 6,
      'i': 1736343324,
      'w': 6,
      'x': 0,
      'y': 16
    },
    {
      'h': 18,
      'i': 1736343325,
      'w': 6,
      'x': 6,
      'y': 10
    },
    {
      'h': 2,
      'i': 1736343326,
      'w': 4,
      'x': 8,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343327,
      'w': 4,
      'x': 8,
      'y': 8
    },
    {
      'h': 2,
      'i': 1736343328,
      'w': 4,
      'x': 4,
      'y': 8
    },
    {
      'h': 6,
      'i': 1736343329,
      'w': 6,
      'x': 0,
      'y': 22
    },
    {
      'h': 6,
      'i': 1736343330,
      'w': 6,
      'x': 0,
      'y': 10
    },
    {
      'h': 7,
      'i': 1736353311,
      'w': 12,
      'x': 0,
      'y': 28
    }
  ]
}

const renewalManagementDashboard = {
  title: 'Renewal Management',
  description: 'Monitor and optimize contract renewals, license utilization, and contract ownership.',
  widgets: [
    {
      'id': 1736343500,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'Status',
              'value': 'status'
            },
            'value': {
              'label': 'active',
              'value': 'active'
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Active contract value',
        'metric': {
          'func': 'sum',
          'field': {
            'type': 'currency',
            'label': 'Contract value',
            'systemKey': 'amount'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'amount:desc'
        ],
        'fields': [
          'name',
          'idApp',
          'amount',
          'startDate',
          'endDate',
          'owner'
        ]
      }
    },
    {
      'id': 1736343501,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'Status',
              'value': 'status'
            },
            'value': {
              'label': 'active',
              'value': 'active'
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Active contract count',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'idApp:desc'
        ],
        'fields': [
          'name',
          'idApp',
          'amount',
          'startDate',
          'endDate',
          'owner'
        ]
      }
    },
    {
      'id': 1736343502,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1741701535687,
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'idApp': null,
              'label': 'Status',
              'value': 'status'
            },
            'value': {
              'color': '#00E0CF',
              'label': 'Active',
              'value': 'active',
              'idField': 2,
              'isPredefined': 1
            }
          },
          {
            'id': 1741701556918,
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'idApp': null,
              'label': 'End date',
              'value': 'endDate'
            },
            'value': {
              'type': {
                'label': 'days from now',
                'value': 'from'
              },
              'number': '90'
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Upcoming renewals count (90 days)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'endDate:asc'
        ],
        'fields': [
          'name',
          'idApp',
          'amount',
          'startDate',
          'endDate',
          'owner'
        ]
      }
    },
    {
      'id': 1736343512,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1741701535687,
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'idApp': null,
              'label': 'Status',
              'value': 'status'
            },
            'value': {
              'color': '#00E0CF',
              'label': 'Active',
              'value': 'active',
              'idField': 2,
              'isPredefined': 1
            }
          },
          {
            'id': 1741701556918,
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'idApp': null,
              'label': 'End date',
              'value': 'endDate'
            },
            'value': {
              'type': {
                'label': 'days from now',
                'value': 'from'
              },
              'number': '90'
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Upcoming renewals value (90 days)',
        'metric': {
          'func': 'sum',
          'field': {
            'type': 'currency',
            'label': 'Contract value',
            'systemKey': 'amount'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'endDate:asc'
        ],
        'fields': [
          'name',
          'idApp',
          'amount',
          'startDate',
          'endDate',
          'owner'
        ]
      }
    },
    {
      'id': 1736343503,
      'type': 'fixedWidget',
      'displayConfig': {
        'label': 'Renewal yearly summary'
      },
      'fixedWidgetType': 'renewalYearlySummary'
    },
    {
      'id': 1736343504,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1741698046330,
            'op': {
              'type': 'number',
              'label': 'greater than or equals',
              'value': 'gte'
            },
            'key': {
              'name': 'Paid license utilization (%)',
              'type': 'number',
              'label': 'Paid license utilization (%)',
              'value': 'paidLicensesUtilizationPercent',
              'systemKey': 'paidLicensesUtilizationPercent'
            },
            'value': 85
          },
          {
            'id': 1741701200634,
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'name': 'Active contract end date',
              'type': 'date',
              'label': 'Active contract end date',
              'value': 'activeContractTerm.latestEndDate',
              'scopes': [
                'contracts:read',
                'app-owner:read'
              ],
              'systemKey': 'activeContractTerm.latestEndDate'
            },
            'value': {
              'type': {
                'label': 'days from now',
                'value': 'from'
              },
              'number': '90'
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'sort': [
          'paidLicensesUtilizationPercent:desc'
        ],
        'label': 'Apps nearing license capacity',
        'fields': [
          'name',
          'paidLicensesUtilizationPercent',
          'activeContractTerm.latestEndDate'
        ]
      },
      'drillDownConfig': {
        'sort': [
          'paidLicensesUtilizationPercent:desc'
        ],
        'fields': [
          'name',
          'state',
          'displayActiveUsersCount',
          'paidLicensesUtilizationPercent',
          'activeContractTerm.latestEndDate',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343505,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1741698315419,
            'op': {
              'type': 'number',
              'label': 'lower than or equals',
              'value': 'lte'
            },
            'key': {
              'name': 'Paid license utilization (%)',
              'type': 'number',
              'label': 'Paid license utilization (%)',
              'value': 'paidLicensesUtilizationPercent',
              'systemKey': 'paidLicensesUtilizationPercent'
            },
            'value': 35
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'sort': [
          'paidLicensesUtilizationPercent:asc'
        ],
        'label': 'Apps with low license utilization',
        'fields': [
          'name',
          'paidLicensesUtilizationPercent',
          'displayActiveUsersCount'
        ]
      },
      'drillDownConfig': {
        'sort': [
          'paidLicensesUtilizationPercent:asc'
        ],
        'fields': [
          'name',
          'state',
          'displayActiveUsersCount',
          'paidLicensesUtilizationPercent',
          'activeContractTerm.latestEndDate',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343506,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'Status',
              'value': 'status'
            },
            'value': {
              'color': '#FB1D73',
              'label': 'Closed',
              'value': 'closed',
              'idField': 2,
              'isPredefined': 1
            }
          },
          {
            'op': {
              'label': 'is not set',
              'value': 'isNotSet'
            },
            'key': {
              'type': 'user',
              'label': 'Contract Owner',
              'value': 'owner'
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Contracts without owners',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'startDate',
          'endDate',
          'amount',
          'owner',
          'status'
        ]
      }
    },
    {
      'id': 1736343507,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          },
          {
            'id': 1742203123961,
            'op': {
              'label': 'is set',
              'value': 'isSet'
            },
            'key': {
              'name': 'License',
              'type': 'dropdownMulti',
              'label': 'License',
              'value': 'licenses.name',
              'systemKey': 'licenses.name'
            }
          }
        ]
      },
      'entityType': 'appUsers',
      'displayConfig': {
        'label': 'Past users with active licenses',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'originEmail',
          'appName',
          'appAccountName',
          'externalStatus',
          'licenses.name'
        ]
      }
    },
    {
      'id': 1736343508,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1741700505240,
            'op': {
              'type': 'number',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'Active contracts',
              'type': 'number',
              'label': 'Active contracts',
              'value': 'activeContractsCount',
              'scopes': [
                'contracts:read',
                'app-owner:read'
              ],
              'systemKey': 'activeContractsCount'
            },
            'value': 0
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1742285755859,
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'name': 'Expenses (last 12 months)',
              'type': 'number',
              'label': 'Expenses (last 12 months)',
              'value': 'expenses',
              'scopes': [
                'expense:read',
                'app-owner:read'
              ],
              'systemKey': 'expenses'
            },
            'value': 0
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps without active contracts',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'expenses',
          'primaryOwner',
          'displayActiveUsersCount',
          'state',
          'activeContractsCount'
        ]
      }
    },
    {
      'id': 1736343509,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1741699159584,
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'name': 'Expenses compared to contract value (%)',
              'type': 'number',
              'label': 'Expenses compared to contract value (%)',
              'value': 'expensesComparedToActiveContractValue',
              'scopes': [
                'expense:read',
                'app-owner:read'
              ],
              'systemKey': 'expensesComparedToActiveContractValue'
            },
            'value': 0
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'sort': [
          'expensesComparedToActiveContractValue:desc'
        ],
        'label': 'Apps with expenses exceeding contract value',
        'fields': [
          'name',
          'expensesComparedToActiveContractValue',
          'primaryOwner'
        ]
      },
      'drillDownConfig': {
        'sort': [
          'expensesComparedToActiveContractValue:desc'
        ],
        'fields': [
          'name',
          'state',
          'expensesInActiveContractTerm',
          'activeContractsTotalValue',
          'expensesComparedToActiveContractValue',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343510,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1741699342950,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'dropdown',
              'idApp': null,
              'label': 'Status',
              'value': 'status'
            },
            'value': {
              'color': '#FB1D73',
              'label': 'Closed',
              'value': 'closed',
              'idField': 2,
              'isPredefined': 1
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Contracts by status',
        'groupBy': {
          'size': 5,
          'sort': {
            'order': 'desc',
            'aggFunc': 'total'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Status',
            'systemKey': 'status'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'status:desc'
        ],
        'fields': [
          'name',
          'idApp',
          'status',
          'amount',
          'startDate',
          'endDate'
        ]
      }
    },
    {
      'id': 1736343511,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'Is past user',
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources',
              'systemKey': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          },
          {
            'op': {
              'label': 'is set',
              'value': 'isSet'
            },
            'key': {
              'name': 'License',
              'type': 'dropdownMulti',
              'label': 'License',
              'value': 'licenses.name',
              'systemKey': 'licenses.name'
            }
          }
        ]
      },
      'entityType': 'appUsers',
      'displayConfig': {
        'label': 'Past users with active licenses - by app',
        'groupBy': {
          'size': 5,
          'sort': {
            'order': 'desc',
            'aggFunc': 'total'
          },
          'field': {
            'type': 'dropdown',
            'label': 'App name',
            'systemKey': 'appName'
          },
          'metric': {
            'func': 'total'
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'originEmail',
          'appName',
          'appAccountName',
          'externalStatus',
          'licenses.name'
        ]
      }
    },
    {
      'id': 1736343513,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1741699296472,
            'op': {
              'type': 'number',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'Active contracts',
              'type': 'number',
              'label': 'Active contracts',
              'value': 'activeContractsCount',
              'scopes': [
                'contracts:read',
                'app-owner:read'
              ],
              'systemKey': 'activeContractsCount'
            },
            'value': 0
          },
          {
            'id': 1742118997407,
            'op': {
              'type': 'number',
              'label': 'greater than or equals',
              'value': 'gte'
            },
            'key': {
              'name': 'Expenses (last 12 months)',
              'type': 'number',
              'label': 'Expenses (last 12 months)',
              'value': 'expenses',
              'scopes': [
                'expense:read',
                'app-owner:read'
              ],
              'systemKey': 'expenses'
            },
            'value': 0
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Missing contracts by category',
        'groupBy': {
          'size': 5,
          'sort': {
            'order': 'desc',
            'aggFunc': 'total'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Category',
            'systemKey': 'category'
          },
          'metric': {
            'func': 'total'
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'category',
          'expenses',
          'primaryOwner',
          'displayActiveUsersCount',
          'state'
        ]
      }
    },
    {
      'id': 1736343514,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1741701556919,
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'label': 'End date',
              'value': 'endDate'
            },
            'value': {
              'type': {
                'label': 'days from now',
                'value': 'from'
              },
              'number': '90'
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'sort': ['endDate:asc'],
        'label': 'Upcoming renewals (90 days)',
        'fields': ['name', 'idApp', 'endDate']
      },
      'drillDownConfig': {
        'sort': ['endDate:asc'],
        'fields': ['name', 'idApp', 'startDate', 'endDate']
      }
    }
  ],
  layoutConfig: [
    {
      'h': 2,
      'i': 1736343500,
      'w': 3,
      'x': 0,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343501,
      'w': 3,
      'x': 3,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343502,
      'w': 3,
      'x': 9,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343512,
      'w': 3,
      'x': 6,
      'y': 0
    },
    {
      'h': 9,
      'i': 1736343503,
      'w': 12,
      'x': 0,
      'y': 2
    },
    {
      'h': 7,
      'i': 1736343504,
      'w': 6,
      'x': 0,
      'y': 19
    },
    {
      'h': 7,
      'i': 1736343505,
      'w': 6,
      'x': 6,
      'y': 19
    },
    {
      'h': 2,
      'i': 1736343506,
      'w': 3,
      'x': 6,
      'y': 33
    },
    {
      'h': 2,
      'i': 1736343507,
      'w': 3,
      'x': 6,
      'y': 35
    },
    {
      'h': 2,
      'i': 1736343508,
      'w': 3,
      'x': 6,
      'y': 37
    },
    {
      'h': 8,
      'i': 1736343509,
      'w': 6,
      'x': 0,
      'y': 11
    },
    {
      'h': 6,
      'i': 1736343510,
      'w': 6,
      'x': 0,
      'y': 33
    },
    {
      'h': 7,
      'i': 1736343511,
      'w': 6,
      'x': 6,
      'y': 26
    },
    {
      'h': 7,
      'i': 1736343513,
      'w': 6,
      'x': 0,
      'y': 26
    },
    {
      'h': 8,
      'i': 1736343514,
      'w': 6,
      'x': 6,
      'y': 11
    }
  ]
}

const internalTestDashboard = {
  title: 'Test Dashboard',
  description: 'Dashboard for internal testing',
  widgets: [
    {
      'id': 1736343296,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'User Type',
              'value': 'userType'
            },
            'value': {
              'label': 'employee',
              'value': 'employee'
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Active employees (Not in MVP)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 1736343297,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is External',
              'value': 'isExternal'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Past users with app access (Not in MVP)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'firstName',
          'lastName',
          'email',
          'isExternal',
          'creationTime',
          'idRole',
          'idOrg',
          'status',
          'lifecycleStatus',
          'isDeletedInIdentitySources',
          'identitySourcesDeletionTime',
          'activeAppsCount',
          'photoUrl',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 1736343298,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is external',
              'value': 'isExternal'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'type': 'number',
              'label': 'Number of apps used',
              'value': 'activeAppsCount'
            },
            'value': 0
          },
          {
            'id': 1733221913007,
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'idApp': 3506,
              'label': 'Hibob Start Date',
              'value': 'hibob_startDate'
            },
            'value': {
              'type': {
                'label': 'days ago',
                'value': 'ago'
              },
              'number': '90'
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Recent hires (Not in MVP)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'firstName',
          'lastName',
          'email',
          'isExternal',
          'creationTime',
          'idRole',
          'idOrg',
          'status',
          'lifecycleStatus',
          'isDeletedInIdentitySources',
          'identitySourcesDeletionTime',
          'activeAppsCount',
          'photoUrl',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 1736343299,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'idApp': null,
              'label': 'End date',
              'value': 'endDate'
            },
            'value': {
              'type': {
                'label': 'days from now',
                'value': 'from'
              },
              'number': '30'
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Upcoming contract renewals (Not in MVP)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'createdBy',
          'startDate',
          'endDate'
        ]
      }
    },
    {
      'id': 1736343300,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Applications last 12 months expenses Sum (Not in MVP)',
        'metric': {
          'func': 'sum',
          'field': {
            'type': 'currency',
            'label': 'Expenses (last 12 months)',
            'systemKey': 'expenses'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'category'
        ]
      }
    },
    {
      'id': 1736343301,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'User Type',
              'value': 'userType'
            },
            'value': {
              'label': 'employee',
              'value': 'employee'
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Active users by department (Not in MVP)',
        'groupBy': {
          'size': 5,
          'sort': {
            'aggFunc': 'total',
            'order': 'desc'
          },
          'field': {
            'type': 'singleLine',
            'label': 'Google Workspace Department',
            'systemKey': 'gsuite_department'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 1736343302,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Contracts grouped by Status (Not in MVP)',
        'groupBy': {
          'size': 5,
          'sort': {
            'aggFunc': 'total',
            'order': 'desc'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Status',
            'systemKey': 'status'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'createdBy',
          'startDate',
          'endDate'
        ]
      }
    },
    {
      'id': 1736343303,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps grouped by Tag (Not in MVP)',
        'groupBy': {
          'size': 5,
          'sort': {
            'aggFunc': 'total',
            'order': 'desc'
          },
          'field': {
            'type': 'dropdownMulti',
            'label': 'Tags',
            'systemKey': 'tags'
          },
          'metric': {
            'func': 'total'
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'tags',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343304,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps by category (Not in MVP)',
        'groupBy': {
          'size': 5,
          'sort': {
            'aggFunc': 'total',
            'order': 'desc'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Category',
            'systemKey': 'category'
          },
          'metric': {
            'func': 'total'
          }
        },
        'barOrientation': 'vertical'
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'category',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343305,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Applications group by Category total expenses (Not in MVP)',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'expenses',
            'aggFunc': 'sum',
            'order': 'desc'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Category',
            'systemKey': 'category'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Expenses (last 12 months)',
              'systemKey': 'expenses'
            }
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'category'
        ]
      }
    },
    {
      'id': 1736343306,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'User Type',
              'value': 'userType'
            },
            'value': {
              'label': 'employee',
              'value': 'employee'
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'The total cost of active users by department (Not in MVP)',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'annualCostConverted',
            'aggFunc': 'sum',
            'order': 'desc'
          },
          'field': {
            'type': 'singleLine',
            'label': 'Google Workspace Department',
            'systemKey': 'gsuite_department'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Annual cost',
              'systemKey': 'annualCostConverted'
            }
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'additionalEmails'
        ]
      }
    },
    {
      'id': 1736343307,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'The total amount of contracts grouped by Status (Not in MVP)',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'amount',
            'aggFunc': 'sum',
            'order': 'desc'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Status',
            'systemKey': 'status'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Contract value',
              'systemKey': 'amount'
            }
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'createdBy',
          'startDate',
          'endDate'
        ]
      }
    },
    {
      'id': 1736343308,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps discovery by quarter (Not in MVP)',
        'groupBy': {
          'size': 5,
          'sort': {
            'aggFunc': 'total',
            'order': 'desc'
          },
          'field': {
            'type': 'date',
            'label': 'Discovery date',
            'systemKey': 'creationTime'
          },
          'metric': {
            'func': 'total'
          },
          'datePeriod': 'quarterly'
        },
        'barOrientation': 'vertical'
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'tags',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1736343309,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Contract value by end month (Not in MVP)',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'amount',
            'aggFunc': 'sum',
            'order': 'desc'
          },
          'field': {
            'type': 'datePicker',
            'label': 'End date',
            'systemKey': 'endDate'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Contract value',
              'systemKey': 'amount'
            }
          },
          'datePeriod': 'monthly'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'createdBy',
          'startDate',
          'endDate',
          'amount'
        ]
      }
    },
    ...fixedWidgets.widgets
  ],
  layoutConfig: [
    {
      'h': 2,
      'i': 1736343296,
      'w': 3,
      'x': 0,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343297,
      'w': 3,
      'x': 3,
      'y': 0
    },
    {
      'h': 2,
      'i': 1736343298,
      'w': 3,
      'x': 0,
      'y': 2
    },
    {
      'h': 2,
      'i': 1736343299,
      'w': 3,
      'x': 3,
      'y': 2
    },
    {
      'h': 4,
      'i': 1736343300,
      'w': 6,
      'x': 6,
      'y': 0
    },
    {
      'h': 6,
      'i': 1736343301,
      'w': 6,
      'x': 6,
      'y': 4
    },
    {
      'h': 6,
      'i': 1736343302,
      'w': 6,
      'x': 0,
      'y': 4
    },
    {
      'h': 6,
      'i': 1736343303,
      'w': 6,
      'x': 6,
      'y': 10
    },
    {
      'h': 6,
      'i': 1736343304,
      'w': 6,
      'x': 0,
      'y': 10
    },
    {
      'h': 6,
      'i': 1736343305,
      'w': 6,
      'x': 0,
      'y': 16
    },
    {
      'h': 6,
      'i': 1736343306,
      'w': 6,
      'x': 6,
      'y': 16
    },
    {
      'h': 6,
      'i': 1736343307,
      'w': 12,
      'x': 0,
      'y': 22
    },
    {
      'h': 6,
      'i': 1736343308,
      'w': 6,
      'x': 0,
      'y': 28
    },
    {
      'h': 6,
      'i': 1736343309,
      'w': 6,
      'x': 6,
      'y': 28
    },
    ...fixedWidgets.layout
  ]
}

const productTestDashboard = {
  id: 'showTestDashboard',
  systemKey: 'productTestDashboard',
  title: 'Product Widget Test Dashboard',
  description: 'Dashboard demonstrating all widget types across all data sources',
  widgets: [
    // Metric Widgets
    {
      'id': 1736343400,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Total Apps',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': ['name:asc'],
        'fields': ['name', 'state', 'displayActiveUsersCount', 'expenses']
      }
    },
    {
      'id': 1736343401,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Active Users',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': ['fullName:asc'],
        'fields': ['fullName', 'activeAppsCount', 'annualCostConverted']
      }
    },
    {
      'id': 1736343402,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Total Contract Value',
        'metric': {
          'func': 'sum',
          'field': {
            'type': 'currency',
            'label': 'Contract value',
            'systemKey': 'amount'
          }
        }
      },
      'drillDownConfig': {
        'sort': ['name:asc'],
        'fields': ['name', 'startDate', 'endDate', 'amount']
      }
    },
    {
      'id': 1736343403,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'transactions',
      'displayConfig': {
        'label': 'Total Transaction Amount',
        'metric': {
          'func': 'sum',
          'field': {
            'type': 'currency',
            'label': 'Amount',
            'systemKey': 'amount'
          }
        }
      },
      'drillDownConfig': {
        'sort': ['transactionDate:desc'],
        'fields': ['transactionDate', 'amount', 'description', 'appName']
      }
    },
    {
      'id': 1736343412,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'Is past user',
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources',
              'systemKey': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          },
          {
            'op': {
              'label': 'is set',
              'value': 'isSet'
            },
            'key': {
              'name': 'License',
              'type': 'dropdownMulti',
              'label': 'License',
              'value': 'licenses.name',
              'systemKey': 'licenses.name'
            }
          }
        ]
      },
      'entityType': 'appUsers',
      'displayConfig': {
        'label': 'Past users with active licenses',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'originEmail',
          'appName',
          'appAccountName',
          'externalStatus',
          'licenses.name',
          'role'
        ]
      }
    },
    // Pie Chart Widgets
    {
      'id': 1736343404,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps by Category',
        'groupBy': {
          'size': 5,
          'sort': {
            'aggFunc': 'total',
            'order': 'desc'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Category',
            'systemKey': 'category'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': ['name:asc'],
        'fields': ['name', 'category', 'state', 'expenses']
      }
    },
    {
      'id': 1736343405,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Users by Department',
        'groupBy': {
          'size': 5,
          'sort': {
            'aggFunc': 'total',
            'order': 'desc'
          },
          'field': {
            'type': 'singleLine',
            'label': 'Google Workspace Department',
            'systemKey': 'gsuite_department'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': ['fullName:asc'],
        'fields': ['fullName', 'gsuite_department', 'activeAppsCount']
      }
    },
    {
      'id': 1736343408,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'dropdown',
              'label': 'Matching status',
              'value': 'mappingStatus'
            },
            'value': {
              'label': 'Matched',
              'value': 'mapped'
            }
          }
        ]
      },
      'entityType': 'transactions',
      'displayConfig': {
        'label': 'Transactions by App',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'amount',
            'aggFunc': 'sum',
            'order': 'desc'
          },
          'field': {
            'type': 'text',
            'label': 'App name',
            'systemKey': 'appName'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Amount',
              'systemKey': 'amount'
            }
          }
        }
      },
      'drillDownConfig': {
        'sort': ['transactionDate:desc'],
        'fields': ['transactionDate', 'amount', 'description', 'appName']
      }
    },
    {
      'id': 1736343409,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Contracts by Status',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'amount',
            'aggFunc': 'sum',
            'order': 'desc'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Status',
            'systemKey': 'status'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': ['name:asc'],
        'fields': ['name', 'status', 'startDate', 'endDate', 'amount']
      }
    },
    // Bar Chart Widgets
    {
      'id': 1736343406,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'transactions',
      'displayConfig': {
        'label': 'Monthly Transaction Amounts',
        'groupBy': {
          'size': 12,
          'sort': {
            'field': 'transactionDate',
            'order': 'asc'
          },
          'field': {
            'type': 'date',
            'label': 'Transaction date',
            'systemKey': 'transactionDate'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Amount',
              'systemKey': 'amount'
            }
          },
          'datePeriod': 'monthly'
        },
        'barOrientation': 'vertical'
      },
      'drillDownConfig': {
        'sort': ['transactionDate:desc'],
        'fields': ['transactionDate', 'amount', 'description', 'appName']
      }
    },
    {
      'id': 1736343407,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'contracts',
      'displayConfig': {
        'label': 'Contract Value by Status',
        'groupBy': {
          'size': 5,
          'sort': {
            'field': 'amount',
            'aggFunc': 'sum',
            'order': 'desc'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Status',
            'systemKey': 'status'
          },
          'metric': {
            'func': 'sum',
            'field': {
              'type': 'currency',
              'label': 'Contract value',
              'systemKey': 'amount'
            }
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': ['amount:desc'],
        'fields': ['name', 'status', 'amount', 'startDate', 'endDate']
      }
    },
    {
      'id': 1736343410,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Top Apps by Users',
        'groupBy': {
          'size': 10,
          'sort': {
            'field': 'displayActiveUsersCount',
            'order': 'desc',
            'aggFunc': 'max'
          },
          'field': {
            'type': 'text',
            'label': 'App name',
            'systemKey': 'name'
          },
          'metric': {
            'func': 'max',
            'field': {
              'type': 'number',
              'label': 'Number of users',
              'systemKey': 'displayActiveUsersCount'
            }
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': ['displayActiveUsersCount:desc'],
        'fields': ['name', 'state', 'displayActiveUsersCount', 'score', 'expenses']
      }
    },
    {
      'id': 1736343411,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is Past User',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Users by Number of Apps',
        'groupBy': {
          'size': 10,
          'sort': {
            'field': 'activeAppsCount',
            'order': 'desc',
            'aggFunc': 'max'
          },
          'field': {
            'type': 'text',
            'label': 'User',
            'systemKey': 'fullName'
          },
          'metric': {
            'func': 'max',
            'field': {
              'type': 'number',
              'label': 'Number of apps used',
              'systemKey': 'activeAppsCount'
            }
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': ['activeAppsCount:desc'],
        'fields': ['fullName', 'activeAppsCount', 'annualCostConverted', 'gsuite_department']
      }
    },
    {
      'id': 1736343413,
      'type': 'barChartWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'Is past user',
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources',
              'systemKey': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'name': 'Last used date',
              'type': 'date',
              'label': 'Last used date',
              'value': 'lastVisitTime',
              'systemKey': 'lastVisitTime'
            },
            'value': {
              'type': {
                'label': 'days ago',
                'value': 'ago'
              },
              'number': '30'
            }
          },
          {
            'op': {
              'type': 'dropdownMulti',
              'label': 'is none of',
              'value': 'noneOf'
            },
            'key': {
              'name': 'Status in app',
              'type': 'text',
              'label': 'Status in app',
              'value': 'externalStatus',
              'systemKey': 'externalStatus'
            },
            'value': [
              {
                'label': 'deleted',
                'value': 'deleted'
              },
              {
                'label': 'no longer in app',
                'value': 'no longer in app'
              },
              {
                'label': 'suspended',
                'value': 'suspended'
              }
            ]
          }
        ]
      },
      'entityType': 'appUsers',
      'displayConfig': {
        'label': 'Active users per app',
        'groupBy': {
          'size': 10,
          'sort': {
            'aggFunc': 'total',
            'order': 'desc'
          },
          'field': {
            'type': 'singleLine',
            'label': 'App name',
            'systemKey': 'appName'
          },
          'metric': {
            'func': 'total'
          }
        },
        'barOrientation': 'horizontal'
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'originEmail',
          'appName',
          'appAccountName',
          'lastVisitTime',
          'licenses.name'
        ]
      }
    },
    // Table widgets
    {
      'id': 1736343414,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'sort': ['name:desc'],
        'label': 'Apps in a table',
        'fields': [
          'name',
          'state',
          'category',
          'displayActiveUsersCount',
          'score',
          'primaryOwner'
        ]
      },
      'drillDownConfig': {
        'sort': ['name:asc'],
        'fields': [
          'name',
          'state',
          'category',
          'displayActiveUsersCount',
          'score',
          'expenses',
          'primaryOwner',
          'creationTime'
        ]
      }
    },
    {
      'id': 1736343415,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
        ]
      },
      'entityType': 'appUsers',
      'displayConfig': {
        'sort': [
          'fullName:asc'
        ],
        'label': 'App Users in a table',
        'fields': [
          'fullName',
          'originEmail',
          'appName',
          'appAccountName',
          'lastVisitTime',
          'licenses.name',
          'externalStatus'
        ]
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'originEmail',
          'appName',
          'appAccountName',
          'lastVisitTime',
          'licenses.name',
          'externalStatus',
          'userType'
        ]
      }
    },
    {
      'id': 1736343416,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'sort': ['fullName:desc'],
        'label': 'Users in a table',
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'isDeletedInIdentitySources'
        ]
      },
      'drillDownConfig': {
        'sort': ['fullName:desc'],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'isDeletedInIdentitySources'
        ]
      }
    },
    {
      'id': 1736343417,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1741701556919,
            'op': {
              'type': 'relativeDate',
              'label': 'is less than',
              'value': 'relativeDateLess'
            },
            'key': {
              'type': 'date',
              'label': 'End date',
              'value': 'endDate'
            },
            'value': {
              'type': {
                'label': 'days from now',
                'value': 'from'
              },
              'number': '90'
            }
          }
        ]
      },
      'entityType': 'contracts',
      'displayConfig': {
        'sort': ['endDate:asc'],
        'label': 'Upcoming renewals (90 days)',
        'fields': ['name', 'idApp', 'endDate']
      },
      'drillDownConfig': {
        'sort': ['endDate:asc'],
        'fields': ['name', 'idApp', 'startDate', 'endDate']
      }
    },
    {
      'id': 1736343418,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': []
      },
      'entityType': 'transactions',
      'displayConfig': {
        'sort': ['transactionDate:desc'],
        'label': 'Transactions in a table',
        'fields': ['transactionDate', 'amount', 'description', 'appName']
      },
      'drillDownConfig': {
        'sort': ['transactionDate:desc'],
        'fields': ['transactionDate', 'amount', 'description', 'appName']
      }
    }
  ],
  layoutConfig: [
    { h: 2, i: 1736343400, w: 3, x: 0, y: 0 },
    { h: 2, i: 1736343401, w: 3, x: 3, y: 0 },
    { h: 2, i: 1736343402, w: 3, x: 6, y: 0 },
    { h: 2, i: 1736343403, w: 3, x: 9, y: 0 },
    { h: 6, i: 1736343404, w: 6, x: 0, y: 2 },
    { h: 6, i: 1736343405, w: 6, x: 6, y: 2 },
    { h: 6, i: 1736343408, w: 6, x: 0, y: 8 },
    { h: 6, i: 1736343409, w: 6, x: 6, y: 8 },
    { h: 6, i: 1736343406, w: 6, x: 0, y: 14 },
    { h: 6, i: 1736343407, w: 6, x: 6, y: 14 },
    { h: 6, i: 1736343410, w: 6, x: 0, y: 20 },
    { h: 6, i: 1736343411, w: 6, x: 6, y: 20 },
    { h: 2, i: 1736343412, w: 12, x: 0, y: 2 },
    { h: 6, i: 1736343413, w: 6, x: 0, y: 32 },
    { h: 6, i: 1736343414, w: 12, x: 0, y: 32 },
    { h: 6, i: 1736343415, w: 12, x: 0, y: 38 },
    { h: 6, i: 1736343416, w: 12, x: 0, y: 44 },
    { h: 6, i: 1736343417, w: 12, x: 0, y: 50 },
    { h: 6, i: 1736343418, w: 12, x: 0, y: 56 }
  ]
}

const securityComplianceDashboard = {
  title: 'Security & Compliance',
  description: 'Gain proactive insights into risks in your SaaS stack and take action to mitigate them effectively.',
  widgets: [
    {
      'id': 1742285755860,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'All apps',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'creationTime',
          'primaryOwner',
          'displayActiveUsersCount',
          'score'
        ]
      }
    },
    {
      'id': 1742285755861,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'New app',
              'type': 'bool',
              'label': 'New app',
              'value': 'isNew',
              'systemKey': 'isNew'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Newly discovered apps (last 7 days)',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'state',
          'creationTime',
          'primaryOwner',
          'displayActiveUsersCount',
          'score'
        ]
      }
    },
    {
      'id': 1742285755862,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1737626703880,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'id': 1743429256921,
            'op': {
              'type': 'number',
              'label': 'greater than',
              'value': 'gt'
            },
            'key': {
              'type': 'number',
              'label': 'Number of apps used',
              'value': 'activeAppsCount'
            },
            'value': 0
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Active users',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'activeAppsCount:desc'
        ],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'gsuite_department',
          'gsuite_title',
          'gsuite_userManager'
        ]
      }
    },
    {
      'id': 1742285755863,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1737626703880,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'No',
              'value': false
            }
          },
          {
            'id': 1737626703881,
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'text',
              'label': 'User Type',
              'value': 'userType'
            },
            'value': {
              'label': 'employee',
              'value': 'employee'
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'label': 'Active employees',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'activeAppsCount',
          'annualCostConverted',
          'gsuite_department',
          'gsuite_title',
          'gsuite_userManager'
        ]
      }
    },
    {
      'id': 1742285755864,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1742984703592,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          },
          {
            'id': 1742984718527,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'type': 'bool',
              'label': 'Is app owner',
              'value': 'isAppOwner'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          }
        ]
      },
      'entityType': 'users',
      'displayConfig': {
        'sort': [
          'fullName:asc'
        ],
        'label': 'App owners that left the org',
        'fields': [
          'fullName',
          'numberOfOwnedApps',
          'gsuite_department',
          'gsuite_userManager'
        ]
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'numberOfOwnedApps',
          'gsuite_department',
          'gsuite_title',
          'gsuite_userManager'
        ]
      }
    },
    {
      'id': 1742285755865,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1742984897160,
            'op': {
              'type': 'bool',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'Is past user',
              'type': 'bool',
              'label': 'Is past user',
              'value': 'isDeletedInIdentitySources',
              'systemKey': 'isDeletedInIdentitySources'
            },
            'value': {
              'label': 'Yes',
              'value': true
            }
          },
          {
            'id': 1742980889734,
            'op': {
              'type': 'dropdownMulti',
              'label': 'is none of',
              'value': 'noneOf'
            },
            'key': {
              'name': 'Status in app',
              'type': 'text',
              'label': 'Status in app',
              'value': 'externalStatus',
              'systemKey': 'externalStatus'
            },
            'value': [
              {
                'label': 'deleted',
                'value': 'deleted'
              },
              {
                'label': 'terminated',
                'value': 'terminated'
              },
              {
                'label': 'no longer in app',
                'value': 'no longer in app'
              },
              {
                'label': 'suspended',
                'value': 'suspended'
              },
              {
                'label': 'deprovisioned',
                'value': 'deprovisioned'
              },
              {
                'label': 'deactivated',
                'value': 'deactivated'
              },
              {
                'label': 'inactive',
                'value': 'inactive'
              },
              {
                'label': 'unverified',
                'value': 'unverified'
              },
              {
                'label': 'blocked',
                'value': 'blocked'
              },
              {
                'label': 'enabled',
                'value': 'enabled'
              },
              {
                'label': 'archived',
                'value': 'archived'
              },
              {
                'label': 'disabled',
                'value': 'disabled'
              },
              {
                'label': 'lock',
                'value': 'lock'
              },
              {
                'label': 'closed',
                'value': 'closed'
              },
              {
                'label': 'inactive (basic)',
                'value': 'inactive (basic)'
              },
              {
                'label': 'canceled',
                'value': 'canceled'
              }
            ]
          },
          {
            'id': 1743057874365,
            'op': {
              'label': 'is set',
              'value': 'isSet'
            },
            'key': {
              'name': 'Status in app',
              'type': 'text',
              'label': 'Status in app',
              'value': 'externalStatus',
              'systemKey': 'externalStatus'
            }
          },
          {
            'id': 1743481486351,
            'op': {
              'label': 'is set',
              'value': 'isSet'
            },
            'key': {
              'name': 'Account',
              'type': 'text',
              'label': 'Account',
              'value': 'appAccountName',
              'systemKey': 'appAccountName'
            }
          },
          {
            'id': 1743481494910,
            'op': {
              'type': 'dropdown',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'Torii calculated status',
              'type': 'text',
              'label': 'Torii calculated status',
              'value': 'status',
              'systemKey': 'status'
            },
            'value': {
              'label': 'active',
              'value': 'active'
            }
          }
        ]
      },
      'entityType': 'appUsers',
      'displayConfig': {
        'sort': [
          'fullName:asc'
        ],
        'label': 'Past users with app access',
        'fields': [
          'fullName',
          'appName',
          'appAccountName',
          'externalStatus'
        ]
      },
      'drillDownConfig': {
        'sort': [
          'fullName:asc'
        ],
        'fields': [
          'fullName',
          'originEmail',
          'appName',
          'appAccountName',
          'externalStatus',
          'licenses.name',
          'role'
        ]
      }
    },
    {
      'id': 1742285755866,
      'type': 'fixedWidget',
      'displayConfig': {
        'label': 'Users map'
      },
      'fixedWidgetType': 'usersMap'
    },
    {
      'id': 1742285755867,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'number',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'Number of users',
              'type': 'number',
              'label': 'Number of users',
              'value': 'displayActiveUsersCount',
              'systemKey': 'displayActiveUsersCount'
            },
            'value': 0
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps without users',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'creationTime',
          'primaryOwner',
          'expenses'
        ]
      }
    },
    {
      'id': 1742285755868,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1742974342841,
            'op': {
              'label': 'is not set',
              'value': 'isNotSet'
            },
            'key': {
              'name': 'Primary App Owner',
              'type': 'user',
              'idOrg': null,
              'label': 'Primary App Owner',
              'value': 'primaryOwner',
              'idField': 184,
              'idGroup': null,
              'isShown': 1,
              'category': 'app_details',
              'position': 130,
              'systemKey': 'primaryOwner',
              'usedByCount': 4,
              'formQuestion': null,
              'isPredefined': 1,
              'isPluginGroup': 0,
              'hasSharedValue': 0,
              'isReadOnlyGroup': null,
              'featureSystemKey': null
            }
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps without owners',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'creationTime',
          'displayActiveUsersCount',
          'lastVisitTime',
          'expenses'
        ]
      }
    },
    {
      'id': 1742285755869,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1742980426662,
            'op': {
              'type': 'dropdownMulti',
              'label': 'has none of',
              'value': 'noneOf'
            },
            'key': {
              'name': 'Certifications & Compliance',
              'type': 'dropdownMulti',
              'idOrg': null,
              'label': 'Certifications & Compliance',
              'value': 'certificationsAndCompliance',
              'idField': 6094,
              'idGroup': 961,
              'isShown': 1,
              'category': 'app_details',
              'position': 0,
              'systemKey': 'certificationsAndCompliance',
              'usedByCount': 0,
              'formQuestion': null,
              'isPredefined': 1,
              'isPluginGroup': 0,
              'hasSharedValue': 1,
              'isReadOnlyGroup': 1,
              'featureSystemKey': 'appCompliance'
            },
            'value': [
              {
                'label': 'soc 2',
                'value': 'soc 2'
              },
              {
                'label': 'iso 27001',
                'value': 'iso 27001'
              }
            ]
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps w/o ISO/SOC2 data',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'certificationsAndCompliance',
          'displayActiveUsersCount',
          'score'
        ]
      }
    },
    {
      'id': 1742285755870,
      'type': 'metricWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1742980426662,
            'op': {
              'type': 'dropdownMulti',
              'label': 'has none of',
              'value': 'noneOf'
            },
            'key': {
              'name': 'Certifications & Compliance',
              'type': 'dropdownMulti',
              'idOrg': null,
              'label': 'Certifications & Compliance',
              'value': 'certificationsAndCompliance',
              'idField': 6094,
              'idGroup': 961,
              'isShown': 1,
              'category': 'app_details',
              'position': 0,
              'systemKey': 'certificationsAndCompliance',
              'usedByCount': 0,
              'formQuestion': null,
              'isPredefined': 1,
              'isPluginGroup': 0,
              'hasSharedValue': 1,
              'isReadOnlyGroup': 1,
              'featureSystemKey': 'appCompliance'
            },
            'value': [
              {
                'label': 'gdpr',
                'value': 'gdpr'
              }
            ]
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps w/o GDPR data',
        'metric': {
          'func': 'total'
        }
      },
      'drillDownConfig': {
        'sort': [
          'name:asc'
        ],
        'fields': [
          'name',
          'primaryOwner',
          'certificationsAndCompliance',
          'displayActiveUsersCount',
          'score'
        ]
      }
    },
    {
      'id': 1742285755871,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1737649754498,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps by offboarding configuration',
        'groupBy': {
          'size': 5,
          'sort': {
            'order': 'desc',
            'aggFunc': 'total'
          },
          'field': {
            'type': 'dropdownMulti',
            'label': 'Offboarding configuration status',
            'systemKey': 'offboardingConfigurationStatuses'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'offboardingConfigurationStatuses:desc'
        ],
        'fields': [
          'name',
          'state',
          'primaryOwner',
          'offboardingConfigurationStatuses',
          'displayActiveUsersCount'
        ]
      }
    },
    {
      'id': 1742285755872,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          },
          {
            'id': 1743062812497,
            'op': {
              'type': 'dropdownMulti',
              'label': 'has none of',
              'value': 'noneOf'
            },
            'key': {
              'name': 'Sources',
              'type': 'dropdownMulti',
              'label': 'Sources',
              'value': 'sources',
              'systemKey': 'sources'
            },
            'value': [
              {
                'label': 'google',
                'value': 'google'
              },
              {
                'label': 'jumpcloud',
                'value': 'jumpcloud'
              },
              {
                'label': 'azure_ad',
                'value': 'azure_ad'
              },
              {
                'label': 'okta',
                'value': 'okta'
              },
              {
                'label': 'onelogin',
                'value': 'onelogin'
              },
              {
                'label': 'sailpoint',
                'value': 'sailpoint'
              },
              {
                'label': 'jamf',
                'value': 'jamf'
              },
              {
                'label': 'jumpCloud_mdm',
                'value': 'jumpCloud_mdm'
              },
              {
                'label': 'kandji',
                'value': 'kandji'
              },
              {
                'label': 'microsoftIntune',
                'value': 'microsoftIntune'
              },
              {
                'label': 'workspaceOneUem',
                'value': 'workspaceOneUem'
              }
            ]
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'sort': [
          'displayActiveUsersCount:desc'
        ],
        'label': 'Apps not behind SSO',
        'fields': [
          'name',
          'displayActiveUsersCount',
          'expenses'
        ]
      },
      'drillDownConfig': {
        'sort': [
          'displayActiveUsersCount:desc'
        ],
        'fields': [
          'name',
          'state',
          'primaryOwner',
          'displayActiveUsersCount',
          'score',
          'sources'
        ]
      }
    },
    {
      'id': 1742285755873,
      'type': 'tableWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1743482403290,
            'op': {
              'type': 'number',
              'label': 'is',
              'value': 'equals'
            },
            'key': {
              'name': 'Number of users',
              'type': 'number',
              'label': 'Number of users',
              'value': 'displayActiveUsersCount',
              'systemKey': 'displayActiveUsersCount'
            },
            'value': 1
          },
          {
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'sort': [
          'expenses:desc'
        ],
        'label': 'Apps with only 1 user',
        'fields': [
          'name',
          'score',
          'expenses'
        ]
      },
      'drillDownConfig': {
        'sort': [
          'expenses:desc'
        ],
        'fields': [
          'name',
          'category',
          'state',
          'displayActiveUsersCount',
          'expenses',
          'primaryOwner'
        ]
      }
    },
    {
      'id': 1742285755874,
      'type': 'pieChartWidget',
      'dataConfig': {
        'filters': [
          {
            'id': 1737649754498,
            'op': {
              'type': 'dropdown',
              'label': 'is not',
              'value': 'notEquals'
            },
            'key': {
              'type': 'text',
              'label': 'State',
              'value': 'state'
            },
            'value': {
              'label': 'closed',
              'value': 'closed'
            }
          }
        ]
      },
      'entityType': 'apps',
      'displayConfig': {
        'label': 'Apps by max risk level',
        'groupBy': {
          'size': 5,
          'sort': {
            'order': 'desc',
            'aggFunc': 'total'
          },
          'field': {
            'type': 'dropdown',
            'label': 'Max risk level',
            'systemKey': 'maxRiskLevel'
          },
          'metric': {
            'func': 'total'
          }
        }
      },
      'drillDownConfig': {
        'sort': [
          'maxRiskLevel:desc'
        ],
        'fields': [
          'name',
          'state',
          'displayActiveUsersCount',
          'maxRiskLevel',
          'GSuiteRiskLevel',
          'azureRiskLevel',
          'slackRiskLevel'
        ]
      }
    }
  ],
  layout: [
    { h: 2, i: 1742285755860, w: 3, x: 0, y: 0 },
    { h: 2, i: 1742285755861, w: 3, x: 3, y: 0 },
    { h: 2, i: 1742285755862, w: 3, x: 6, y: 0 },
    { h: 2, i: 1742285755863, w: 3, x: 9, y: 0 },
    { h: 7, i: 1742285755864, w: 6, x: 0, y: 22 },
    { h: 7, i: 1742285755865, w: 6, x: 0, y: 2 },
    { h: 7, i: 1742285755866, w: 6, x: 6, y: 2 },
    { h: 2, i: 1742285755867, w: 3, x: 6, y: 15 },
    { h: 2, i: 1742285755868, w: 3, x: 9, y: 15 },
    { h: 2, i: 1742285755869, w: 3, x: 6, y: 17 },
    { h: 2, i: 1742285755870, w: 3, x: 9, y: 17 },
    { h: 6, i: 1742285755871, w: 6, x: 6, y: 9 },
    { h: 7, i: 1742285755872, w: 6, x: 0, y: 15 },
    { h: 10, i: 1742285755873, w: 6, x: 6, y: 19 },
    { h: 6, i: 1742285755874, w: 6, x: 0, y: 9 }
  ]
}

const initialDashboardsBySystemKey = {
  saasInventory: saasInventoryDashboard,
  costOptimization: costOptimizationDashboard,
  renewalManagement: renewalManagementDashboard,
  securityCompliance: securityComplianceDashboard,
  internalTest: internalTestDashboard,
  productTest: productTestDashboard
}

export default initialDashboardsBySystemKey
