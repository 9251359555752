import React, { ReactElement } from 'react'
import RelativeTeamLink from '@components/relativeTeamLink'
import { Link } from '@toriihq/design-system'
import { analytics } from '@shared/services/workflows/analytics'
import { WORKFLOW_TRIGGER_TYPE } from '@root/shared/types'

interface Props {
  triggerType: WORKFLOW_TRIGGER_TYPE
}

export const UsersAndEmployeesLink = ({
  triggerType
}: Props): ReactElement => {
  return <RelativeTeamLink to='/settings/usersAndEmployees' onClick={() => analytics.trigger.clickOnSettingsLink({ triggerType })}><Link>Settings</Link></RelativeTeamLink>
}
