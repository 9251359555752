import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import keyBy from 'lodash/keyBy'
import partition from 'lodash/partition'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import { getCatalogSettings } from '@actions/'
import * as Style from '@components/appInCatalogBox/style'
import Placeholder from '@components/placeholder'
import { customJoin } from '@lenses/utils'
import { getAppCatalogSettings, getAppCatalogSettingsResources, getIdOrg, isLoadingAppCatalogSettings } from '@selectors/org'
import {
  getAppCatalogPoliciesResourcesSelector,
  getCustomAppCatalogPolicySelector,
  getDefaultAppCatalogPolicySelector,
  isLoadingAppCatalogPoliciesSelector
} from '@selectors/workflows'
import { ANALYTICS_EVENT, APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS, SCOPES, WORKFLOW_TYPES } from '@root/constants'
import { getWorkflowPathPrefix, isCustomAppCatalogPolicy, getInvalidWorkflowTooltipText } from '@lenses/workflows'
import { PolicyCreator, PolicyName, PolicyExecutions, PolicyActivation } from '@components/appInCatalogBox/policyComponents'
import withCreatePolicy from '@root/shared/HOCs/withCreatePolicy'
import EnableFor from '../enableFor'
import VisibleFor from '../visibleFor'
import analytics from '@root/helpers/analytics'
import InvalidIcon from '../workflows/invalidIcon'
import { Button, ButtonType, Link } from '@toriihq/design-system'

const AppInCatalogBox = ({ idApp, appName, onCreateCustomPolicy }) => {
  const dispatch = useDispatch()

  const idOrg = useSelector(getIdOrg)

  const isLoadingCatalogSettings = useSelector(isLoadingAppCatalogSettings)
  const catalogSettings = useSelector(getAppCatalogSettings)
  const catalogSettingsResources = useSelector(getAppCatalogSettingsResources)

  const isLoadingPolicies = useSelector(isLoadingAppCatalogPoliciesSelector)
  const defaultPolicy = useSelector(getDefaultAppCatalogPolicySelector)
  const customPolicy = useSelector((state) => getCustomAppCatalogPolicySelector(state, idApp))
  const { usersMap } = useSelector(getAppCatalogPoliciesResourcesSelector)

  useEffect(() => {
    if (idOrg) {
      dispatch(getCatalogSettings({ idOrg }))
    }
  }, [dispatch, idOrg])

  const loading = isLoadingCatalogSettings || isLoadingPolicies

  const { requestLicenseConfig = [], requestLicenseOptions = [] } = catalogSettings
  const configurationFieldsById = keyBy(requestLicenseConfig, 'id')

  const selectedOption = get(configurationFieldsById, ['actionSelection', 'value'])
  const isComposeEmail = selectedOption === APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.COMPOSE_EMAIL
  const isOpenLink = selectedOption === APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.OPEN_LINK
  const isTriggerWorkflow = selectedOption === APP_CATALOG_REQUEST_ACCESS_BUTTON_ACTIONS.TRIGGER_WORKFLOW

  const requestLicenseOptionsById = keyBy(requestLicenseOptions, 'value')
  const displayName = (requestLicenseOptionsById[selectedOption] || {}).label

  const renderOpenLink = () => {
    const urlField = configurationFieldsById['url'] || {}
    const url = urlField.value
    return (
      <Style.EmailWrapper>
        <Style.Title>Link to:</Style.Title>
        <Link href={url} target='_blank'>{url}</Link>
      </Style.EmailWrapper>
    )
  }

  const renderComposeEmail = () => {
    const emailToUsersById = get(catalogSettingsResources, ['users'], {})
    const emailToField = configurationFieldsById['to'] || {}
    const [idUsers, specialRecipients] = partition(emailToField.value || [], item => Number.isInteger(item))

    const recipientsEmails = idUsers.map(id => get(emailToUsersById, [id, 'email']))
    const specialRecipientsNames = specialRecipients.map(recipientInCamelCase => {
      const recipient = startCase(recipientInCamelCase)
      return capitalize(recipient)
    })
    const emailRecipients = specialRecipientsNames.concat(recipientsEmails)

    return (
      <Style.EmailWrapper>
        <Style.Title>Email to:</Style.Title>
        <Style.EmailTo>{customJoin(emailRecipients)}</Style.EmailTo>
      </Style.EmailWrapper>
    )
  }

  const renderTriggerWorkflow = () => {
    const policy = customPolicy || defaultPolicy
    const policyId = get(policy, ['id'])
    const policyTriggerIdApp = get(policy, ['triggerIdApp'])
    const policyType = get(policy, ['type'])
    const { isValid, isActive } = policy || {}

    const pathSubPrefix = getWorkflowPathPrefix({ workflowType: WORKFLOW_TYPES.appCatalog })
    const pathPrefix = `${pathSubPrefix}/${policyId}`

    const isCustomPolicy = isCustomAppCatalogPolicy({ workflow: { triggerIdApp: policyTriggerIdApp, type: policyType } })

    const tooltipText = getInvalidWorkflowTooltipText({ type: policyType, isValid, isActive, isCustomPolicy })
    const showTooltip = !isValid || !isActive

    return (
      <Style.PolicyWrapper>
        <Style.PolicyHeader>
          <Style.Title>Policy name:</Style.Title>
          {showTooltip && (<InvalidIcon tooltipText={tooltipText} />)}
          <PolicyName policy={policy} pathPrefix={pathPrefix} isCustomPolicy={isCustomPolicy} appName={appName} />
        </Style.PolicyHeader>
        <Style.PolicyInfo>
          <PolicyCreator policy={policy} usersById={usersMap} />
          <PolicyExecutions policy={policy} pathPrefix={pathPrefix} isCustomPolicy={isCustomPolicy} />
          <PolicyActivation policy={policy} />
        </Style.PolicyInfo>
      </Style.PolicyWrapper>
    )
  }

  const renderCustomPolicyButton = () => {
    const policy = customPolicy || defaultPolicy
    const policyTriggerIdApp = get(policy, ['triggerIdApp'])
    const policyType = get(policy, ['type'])
    const isCustomPolicy = isCustomAppCatalogPolicy({ workflow: { triggerIdApp: policyTriggerIdApp, type: policyType } })

    return !isCustomPolicy && (<VisibleFor scopes={[SCOPES.AUTOMATION_READ]}>
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        <Button
          type={ButtonType.secondary}
          onClick={() => onCreateCustomPolicy({
            idOrg,
            idApp,
            appName,
            onAfterCreation: () => analytics.track(ANALYTICS_EVENT.ADD_NEW_CUSTOM_POLICY, {
              'Open from': 'Add new custom policy'
            })
          })}
          label='Add custom policy'
        />
      </EnableFor>
    </VisibleFor>)
  }

  const renderBody = () => (
    <Style.Body>
      <Style.InfoLineWrapper>
        <Style.Title>When user requests access to the app:</Style.Title>
        <Style.ActionName>{displayName}</Style.ActionName>
        {isTriggerWorkflow && renderCustomPolicyButton()}
      </Style.InfoLineWrapper>
      {isOpenLink && renderOpenLink()}
      {isComposeEmail && renderComposeEmail()}
      {isTriggerWorkflow && renderTriggerWorkflow()}
    </Style.Body>
  )

  return (
    <Style.Main>
      <Style.HeaderWrapper>
        <Style.Header>APP APPEARS IN APPLICATION CATALOG</Style.Header>
      </Style.HeaderWrapper>

      <Style.BodyWrapper>
        <Placeholder loading={loading} type='text' rows={1} style={Style.Placeholder}>
          {renderBody()}
        </Placeholder>
      </Style.BodyWrapper>
    </Style.Main>
  )
}

export default withCreatePolicy(AppInCatalogBox)
