import { css } from 'glamor'
import colors from './colors'
import { fontWeight } from '@shared/style/sizes'

const texts = {
  headers: {
    xLargeBold: css({
      fontSize: '40px',
      fontWeight: fontWeight.semiBold
    }),
    xLarge: css({
      fontSize: '40px',
      fontWeight: fontWeight.normal
    }),
    large: css({
      fontSize: '25px',
      fontWeight: fontWeight.semiBold
    }),
    medium: css({
      fontSize: '20px',
      fontWeight: fontWeight.semiBold
    }),
    regular: css({
      fontSize: '18px',
      fontWeight: fontWeight.semiBold
    }),
    small: css({
      fontSize: '16px',
      fontWeight: fontWeight.semiBold
    })
  },
  subTitle: css({
    fontSize: '20px',
    lineHeight: '32px',
    fontFamily: 'Nunito',
    fontWeight: fontWeight.semiBold
  }),
  heading: css({
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: fontWeight.bold,
    color: colors.black
  }),
  subheading: css({
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: fontWeight.semiBold,
    color: colors.grey2
  }),
  body: css({
    color: colors.text,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: fontWeight.normal,
    ' strong': {
      fontWeight: fontWeight.bold
    }
  }),
  caption: css({
    fontSize: '10px',
    fontWeight: fontWeight.normal
  })
}

export default texts
