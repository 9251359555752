import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import { EMPTY_OBJECT, fieldTypes, formFieldTypes } from '@root/constants'
import {
  getAppUsersFieldValues as getAppUsersFieldValuesSelector,
  isAppUsersMetadataLoading
} from '@selectors/appUsers'
import { getAppsUsersFieldValues } from '@store/actions'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getColumns } from '@components/appUsers/columns'
import { DynamicWidgetInternalDataPopupProps } from '../types'
import config from '@root/config'
import { useGetEntityTableData } from '../shared.hooks'

const AppUserDataPopup = (props: DynamicWidgetInternalDataPopupProps): ReactElement | null => {
  const dispatch = useDispatch()
  const { renderFunc, widget, resources, fields = [] } = props

  const [columns, setColumns] = useState<any[]>([])

  const { preDefinedColumnsMapping, configurableColumnsOptions, filtersOptions, customFields, columnsConfiguration } = useGetEntityTableData({ entityType: widget.entityType, fields })

  const idOrg = useSelector(getIdOrg)
  const fieldValues = useSelector(getAppUsersFieldValuesSelector)
  const loadingMetadata = useSelector(isAppUsersMetadataLoading)

  const fetchFieldValues = (field) => {
    const selectedFilterOption = filtersOptions.find(f => f.value === field) || { type: '' }
    const isFieldSupportValues = [fieldTypes.name, fieldTypes.text, fieldTypes.dropdownMulti, fieldTypes.user].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      dispatch(getAppsUsersFieldValues({ idOrg, status: undefined, fields: [field] }))
    }
  }

  const dynamicColumnsInfo = customFields
    .filter(detail => columnsConfiguration[detail.systemKey] && !preDefinedColumnsMapping[detail.systemKey])

  const { apps: appsById = {} } = resources ?? EMPTY_OBJECT
  useDeepCompareEffect(() => {
    setColumns(getColumns({
      tableInfo: { columnsConfiguration, dynamicColumnsInfo },
      appsById,
      appName: undefined,
      customFieldPropsByType: {
        [formFieldTypes.usersDropdown]: ({ field }) => ({ disabled: true })
      },
      includeAppNameColumn: true,
      fieldIdentifier: 'systemKey',
      includeNewHasLicenseColumn: true
    } as any))
  }, [columnsConfiguration, dynamicColumnsInfo, preDefinedColumnsMapping, idOrg, resources])

  const exportToCsv = ({ sort, query, filters }) => {
    const sortParam = `sort=${sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')}`
    const queryParam = `q=${query}`
    const columnsOrder = fields
    const columnsOrdered = columns.sort((a: any, b: any) => columnsOrder.indexOf(a.id || a.accessor) - columnsOrder.indexOf(b.id || b.accessor))

    const fieldsParam = `fields=${columnsOrdered.filter((col: any) => ((col.show === undefined || col.show) && !col.hideFromCSV)).map((col: any) => col.id || col.accessor).flatMap(field => field === 'fullName' ? ['firstName', 'lastName'] : field).join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/appUsers/csv?${sortParam}&${queryParam}&${filtersParam}&${fieldsParam}`
    const newWindow = window.open(url, '_blank') as any
    newWindow.opener = null
  }

  return renderFunc({ columns, filtersOptions, fieldValues, configurableColumnsOptions, fetchFieldValues, exportToCsv, loadingMetadata })
}

export default AppUserDataPopup
