import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import texts from '@shared/style/texts'
import Placeholder from '@components/placeholder'
import FormField from '@components/workflows/formField'
import { Form, Field } from 'react-final-form'
import { groupOptions } from '../selectionBox'
import colors from '@shared/style/colors'
import noop from 'lodash/noop'
import { actionLabelAddonTypes, formFieldTypes, SCOPES, WORKFLOW_TYPES } from '@root/constants'
import EnableFor from '../../enableFor'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import config from '@root/config'
import OfboardingRemindersSettingsSummary from '@components/workflows/offboardingRemindersSettingsSummary'
import parse, { domToReact, attributesToProps } from 'html-react-parser'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import { Button, Spacer, Link, AlertBox, AlertBoxType, Stack } from '@toriihq/design-system'
import debounce from 'lodash/debounce'
import { shouldLoadDynamicFieldsOptions } from '@actions/shouldLoadDynamicFieldsOptions'

const CSS = {
  headerContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '19px'
  }),
  header: css(texts.headers.small, {
    textTransform: 'uppercase'
  }),
  container: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    padding: '20px'
  }),
  formContainer: css({
    maxWidth: '700px'
  }),
  stepsDescription: css({
    marginBottom: '20px'
  }),
  nextButtonContainer: css({
    display: 'flex',
    justifyContent: 'flex-end'
  })
}

const actionLabelAddonMapping = {
  [actionLabelAddonTypes.offboardingRemindersConfig]: {
    component: () => {
      return <OfboardingRemindersSettingsSummary />
    }
  }
}

class StepSetup extends React.Component {
  fieldsSetDefaultValue = {
    [formFieldTypes.account]: (field) => {
      const { options, value } = field
      if (options && options.length) {
        if (value) {
          const updatedSelectedOption = options.find(option => option.id === value.id) || value
          if (!isEqual(value, updatedSelectedOption)) {
            this.onFieldChange(field, updatedSelectedOption)
          }
        } else {
          const { triggerAppAndAccount } = this.props
          const { idAppAccount: triggerIdAppAccount } = triggerAppAndAccount || {}
          const triggerAppAccountOption =
            triggerIdAppAccount &&
            options.find(option => option.id === triggerIdAppAccount)
          if (triggerAppAccountOption) {
            this.onFieldChange(field, triggerAppAccountOption)
          } else if (options.length === 1) {
            this.onFieldChange(field, options[0])
          }
        }
      }
    },
    [formFieldTypes.user]: (field) => {
      const { signedInUserId } = this.props

      if (field.autoSelectTriggerUser && !field.value) {
        this.onFieldChange(field, 'triggerUser')
      } else if (field.autoSelectSignedInUser && !field.value) {
        this.onFieldChange(field, signedInUserId)
      }
    },
    [formFieldTypes.appAndAccount]: (field, triggerAppAndAccount) => {
      if (field.options && !field.value) {
        const specialOption = field.options.find(option => option.isSpecial)
        const app = triggerAppAndAccount && field.options.find(option => option.id === triggerAppAndAccount.idApp && option.idAppAccount === triggerAppAndAccount.idAppAccount)
        if (app) {
          this.onFieldChange(field, app)
        } else if (specialOption) {
          this.onFieldChange(field, specialOption)
        }
      }
    },
    [formFieldTypes.app]: (field) => {
      if (field.options && !field.value) {
        const specialOption = field.options.find(option => option.isSpecial)
        if (specialOption) {
          this.onFieldChange(field, specialOption)
        }
      }
    }
  }

  componentDidMount () {
    const { idOrg } = this.props
    if (idOrg) {
      this.loadDynamicFieldsOptions()
      this.loadPersonalizationsOptions()
    }
  }

  componentDidUpdate (prevProps) {
    const { fields, idOrg, action, triggerAppAndAccount, actionConfig } = this.props

    if (!isEqual(fields, prevProps.fields)) {
      fields.forEach(field => {
        this.fieldsSetDefaultValue[field.type] && this.fieldsSetDefaultValue[field.type](field, triggerAppAndAccount)
      })

      const shouldLoadFieldsOptions = fields.some(field => shouldLoadDynamicFieldsOptions({
        currentField: field,
        prevFields: prevProps.fields,
        actionConfig
      }))

      if (shouldLoadFieldsOptions) {
        this.loadDynamicFieldsOptions()
      }
    }

    if (idOrg && (prevProps.idOrg !== idOrg || action.type !== prevProps.action.type)) {
      this.loadDynamicFieldsOptions()
      this.loadPersonalizationsOptions()
    }
  }

  loadDynamicFieldsOptions = debounce(() => {
    const { getActionFieldsOptions, idOrg, action, triggerType, prevActionTypes } = this.props
    getActionFieldsOptions({ idOrg, action, triggerType, prevActionTypes })
  }, 50)

  loadPersonalizationsOptions = () => {
    const { getWorkflowsPersonalizationsConfig, idOrg } = this.props
    const idApp = get(this.props, ['action', 'idApp'], undefined)
    getWorkflowsPersonalizationsConfig({ idOrg, idApp })
  }

  renderField = (props) => {
    const { input, field } = props

    return <FormField
      {...props}
      input={{
        ...input,
        disabled: props.disabled,
        onBlur: e => {
          const selectedValue = e.target ? e.target.value : (e || '')
          input.onBlur(e)
          this.props.onChange({ fieldId: field.id, selectedValue })
        },
        onChange: (e, locallyOnly = false) => {
          const selectedValue = e && e.target ? e.target.value : (e || '')
          input.onChange(e)
          this.onFieldChange(field, selectedValue, locallyOnly)
        }
      }} />
  }

  onFieldChange = (field, selectedValue, locallyOnly = false) => {
    this.props.onChange({ fieldId: field.id, selectedValue }, locallyOnly)
  }

  isDisabled = (field) => {
    const { actionConfig, fields, disabled } = this.props
    const fieldConfig = get(actionConfig, ['inputSchema', field.id]) || {}
    const isValid = fieldConfig.disabledUntilFieldsAreValid ? fieldConfig.disabledUntilFieldsAreValid.every(fieldId => fields.find(field => field.id === fieldId).isValid) : true
    return disabled || !isValid
  }

  isHidden = (field) => {
    const { actionConfig, fields, hiddenFieldsIds = [], supportedFeatures } = this.props
    const fieldConfig = get(actionConfig, ['inputSchema', field.id]) || {}

    if (fieldConfig.isHidden) {
      return true
    }

    if (hiddenFieldsIds.includes(field.id)) {
      return true
    }

    if (fieldConfig.featureFlag && !supportedFeatures[fieldConfig.featureFlag]) {
      return true
    }

    if (!fieldConfig.showOnFieldSpecificValue) {
      return false
    }

    const dependsOnField = fields.find(field => field.id === fieldConfig.showOnFieldSpecificValue.id)
    const dependsOnFieldValue = get(dependsOnField, ['value', 'value']) || dependsOnField.value
    return dependsOnFieldValue !== fieldConfig.showOnFieldSpecificValue.value
  }

  shouldClearOptions = (field) => {
    const { actionConfig } = this.props
    const fieldConfig = get(actionConfig, ['inputSchema', field.id]) || {}
    return Boolean(fieldConfig.disabledUntilFieldsAreValid)
  }

  renderWithLinks = (text) => {
    return parse(text, {
      replace: ({ attribs, children }) => {
        if (attribs && attribs.href) {
          const props = attributesToProps(attribs)
          return <Link {...props}>{domToReact(children)}</Link>
        }
      }
    })
  }

  renderForm = (fields) => {
    const { action, triggerType, personalization, actionConfig, idOrg, triggerAppAndAccount } = this.props
    const stepsDescription = get(actionConfig, ['uiConfig', 'stepsDescription'])
    const appBaseUrlWithOrg = `${config.appBaseUrl}/team/${idOrg}`
    const initialValues = fields.reduce((result, field) => {
      result[field.id] = field.value
      return result
    }, {})

    return <div {...CSS.formContainer}>
      {stepsDescription && <div {...CSS.stepsDescription}>{this.renderWithLinks(stepsDescription.replace('{appBaseUrl}', appBaseUrlWithOrg))}</div>}
      <Form
        onSubmit={noop}
        initialValues={initialValues}
        render={(formProps) => {
          const { handleSubmit } = formProps
          return <form onSubmit={handleSubmit}>
            <div>
              {fields.filter(Boolean).map((field, index) => {
                const isHidden = this.isHidden(field)

                if (isHidden) {
                  return null
                }

                const typeMapping = FormField.mapping[field.type] || {}
                const validate = typeMapping.validators
                const disabled = this.isDisabled(field)
                const loading = this.props.isLoadingFieldOptions
                const configuredField = {
                  ...field,
                  loading,
                  options: loading && this.shouldClearOptions(field) ? [] : field.options
                }
                return (
                  <EnableFor scopes={[SCOPES.AUTOMATION_WRITE, getScopeByIdOrgAndIdApp(SCOPES.AUTOMATION_WRITE, idOrg, action.idApp)]} key={field.id}>
                    <Field
                      className={field.sensitive ? 'fs-exclude' : ''}
                      addFieldIndex={false}
                      index={index}
                      validate={validate}
                      key={configuredField.id}
                      name={configuredField.id}
                      field={configuredField}
                      component={this.renderField}
                      format={typeMapping.format}
                      parse={typeMapping.parse}
                      action={action}
                      triggerType={triggerType}
                      personalization={personalization}
                      disabled={disabled}
                      refreshDynamicFieldsOptions={this.loadDynamicFieldsOptions}
                      triggerAppAndAccount={triggerAppAndAccount} />
                  </EnableFor>
                )
              })}
            </div>
          </form>
        }}
      />
    </div>
  }

  render () {
    const { loading, fields, header, onNext, actionConfig, isValid, noBorder, workflowType, idOrg, action } = this.props
    const requirementsMessage = get(actionConfig, ['uiConfig', 'requirementsMessage'])
    const { link, text: documentationText } = get(actionConfig, ['uiConfig', 'documentation'], {})
    const actionLabelAddon = get(actionConfig, ['uiConfig', 'actionLabelAddon'])
    const hideActionLabelAddon = workflowType === WORKFLOW_TYPES.offboarding && actionLabelAddon && actionLabelAddon.hideInOffboardingFlow

    return (
      <Placeholder loading={loading} type='text' rows={4} style={{ height: '100px', width: '50px' }}>
        <div {...CSS.headerContainer}>
          <div {...CSS.header}>{header}</div>
          <Link href={link} target='_blank'>{documentationText}</Link>
          { actionLabelAddon && !hideActionLabelAddon && actionLabelAddonMapping[actionLabelAddon.type].component() }
        </div>
        <Stack gap='space-200'>
          {requirementsMessage && (
            <AlertBox
              type={AlertBoxType.INFORMATIVE}
              description={this.renderWithLinks(requirementsMessage)}
            />
          )}
          <div {...css(!noBorder && CSS.container)}>
            {this.renderForm(fields)}
          </div>
        </Stack>
        {onNext && <div {...CSS.nextButtonContainer}>
          <Spacer top={'space-300'}>
            <EnableFor scopes={[SCOPES.AUTOMATION_WRITE, getScopeByIdOrgAndIdApp(SCOPES.AUTOMATION_WRITE, idOrg, action.idApp)]}>
              <Button disabled={!isValid} onClick={onNext} label='Next' />
            </EnableFor>
          </Spacer>
        </div>}
      </Placeholder>
    )
  }
}

const action = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  idApp: PropTypes.number,
  fields: PropTypes.array
})

StepSetup.propTypes = {
  action: action.isRequired,
  prevActions: PropTypes.arrayOf(action).isRequired,
  fields: PropTypes.arrayOf(groupOptions),
  triggerType: PropTypes.string,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  onUnmount: PropTypes.func,
  header: PropTypes.string,
  workflowType: PropTypes.string,
  triggerIdAppAccount: PropTypes.number
}

export default StepSetup
