import { css } from 'styled-components'
import colors from './colors'
import { fontWeight } from './sizes'

export const body = css`
  color: ${colors.text};
  font-size: 14px;
  line-height: 20px;
  font-weight: ${fontWeight.normal};
    strong {
      font-weight: ${fontWeight.bold}
  }
`

export const title = css`
  color: ${colors.darkText};
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  font-family: 'Nunito';
  font-weight: ${fontWeight.semiBold}
`
