import type { HandleMarkAsDoneParams, Props } from './types'
import { ActionButton, TaskGroupContent } from './style'
import { compact, isEmpty, omit } from 'lodash'
import { AppIcon, Button, ButtonType, ExpandableSection, Counter, toast, ToastType, Link } from '@toriihq/design-system'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from '@components/table'
import UsersIconsList from '@components/usersIconsList'
import UserDetails from '@components/userDetails'
import { SCOPES, ITEMS_PER_PAGE } from '@root/constants'
import EnableFor from '@components/enableFor'
import { getFormattedDate } from '@lenses/utils'
import { PENDING_TASK_ROW_CLASSNAME, OFFBOARDING_TASKS_GROUP_TYPE } from '../../consts'
import Placeholder from '@components/placeholder'
import { getSelf } from '@selectors/me'
import { getOffboardingInProgressUsers, getOffboardingInProgressUsersCount, updateAppUserRemovalStatus } from '@actions/'
import RelativeTeamLink from '@components/relativeTeamLink'
import type { PendingListResources, User } from '../../types'
import { getPendingOffboardingResourcesSelector } from '@selectors/offboarding'
import { OFFBOARDING_TASK_MARKED_AS_DONE } from '@root/constants.t'
import Analytics from '@helpers/analytics'
import { USER_LIFECYCLE_STATUS } from '@root/shared/types'

const getTrGroupClassName = () => ({
  className: PENDING_TASK_ROW_CLASSNAME
})

export const PendingTasksGroup = ({
  idOrg,
  tasks,
  idApp,
  appAccountName,
  isLoading,
  taskType,
  idAppAccount
}: Props) => {
  const me = useSelector(getSelf)
  const { appsById, usersById }: PendingListResources = useSelector(getPendingOffboardingResourcesSelector)
  const app = appsById[idApp]
  const dispatch = useDispatch()

  const isTicketType = taskType === OFFBOARDING_TASKS_GROUP_TYPE.TICKETS

  const [isOpen, setIsOpen] = useState(false)

  const headerLabel = `${app?.name ?? ''}${appAccountName ? ` - ${appAccountName}` : ''}`

  const handleMarkAsDone = async ({ onUser, idAppAccount, idWorkflowActionExecution }: HandleMarkAsDoneParams) => {
    try {
      await markTaskAsDone(onUser, idAppAccount, idWorkflowActionExecution)
    } catch (err: any) {
      toast({
        message: `Something went wrong, failed to mark pending task for ${headerLabel}`,
        type: ToastType.ERROR
      })
      throw new Error(err)
    }
  }

  const markTaskAsDone = async (onUser, idAppAccount, idWorkflowActionExecution) => {
    const { appsRemovalStatus } = await dispatch(updateAppUserRemovalStatus({
      idOrg,
      triggerIdUser: me.id,
      idUser: onUser,
      idAppAccount,
      isRemoved: true,
      idApp,
      removedTime: undefined,
      idActionExe: idWorkflowActionExecution,
      email: usersById[onUser].email,
      pageName: 'Pending tasks page',
      taskType: taskType === OFFBOARDING_TASKS_GROUP_TYPE.TASKS ? 'Torii task' : 'Jira issue'
    }))

    const { isUserRemovedFromApp } = appsRemovalStatus.length ? appsRemovalStatus[0] : { isUserRemovedFromApp: false }
    if (!isUserRemovedFromApp) {
      throw new Error('User was not removed from the app')
    }

    setTimeout(() => {
      dispatch({
        type: OFFBOARDING_TASK_MARKED_AS_DONE,
        meta: {
          idUser: onUser,
          idApp,
          idAppAccount
        }
      })
      dispatch(getOffboardingInProgressUsers({ idOrg, limit: ITEMS_PER_PAGE, reset: true, sort: undefined, q: undefined }))
      dispatch(getOffboardingInProgressUsersCount({ idOrg }))
    }, 500)
  }

  const getUserWithVisibleLifecycleStatus = (user: User) => {
    if (user.lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDING) {
      return omit(user, ['lifecycleStatus'])
    }
    return user
  }

  const getColumns = () => {
    return [
      {
        Header: !isTicketType ? 'Task Owner' : 'Assigned to',
        accessor: 'idUsers',
        Cell: ({ row: { idUsers } }) => {
          const usersList: User[] = compact(idUsers.map(idUser => usersById[idUser]))
          if (isEmpty(usersList)) {
            return '-'
          }
          const firstUser = getUserWithVisibleLifecycleStatus(usersList[0])
          return (
            usersList.length > 1
              ? <UsersIconsList users={usersList} maxUsers={4} />
              : <UserDetails idUser={firstUser.id} {...firstUser} />
          )
        },
        minWidth: 170
      },
      {
        Header: 'User to offboard',
        accessor: 'onUser',
        Cell: ({ row: { onUser } }) => {
          const user = getUserWithVisibleLifecycleStatus(usersById[onUser])
          return <UserDetails idUser={user.id} {...user} />
        },
        minWidth: 170
      },
      {
        Header: 'Jira Issue',
        accessor: 'jiraIssue',
        Cell: ({ row: { ticketUrl, jiraIssue } }) => {
          if (!ticketUrl) {
            return '-'
          }
          return <Link href={ticketUrl}>{jiraIssue}</Link>
        },
        show: isTicketType
      },
      {
        Header: !isTicketType ? 'Task sent on' : 'Created on',
        accessor: 'creationTime',
        Cell: ({ row: { creationTime } }) => getFormattedDate({ date: creationTime })
      },
      {
        Header: '',
        Cell: ({ row: { onUser, idAppAccount, idWorkflowActionExecution } }) => {
          return <ActionButton>
            <EnableFor scopes={[SCOPES.OFFBOARDING_WRITE]}>
              <Button
                type={ButtonType.secondary}
                label='Mark as done'
                onClick={() => handleMarkAsDone({ onUser, idAppAccount, idWorkflowActionExecution })}
              />
            </EnableFor>
          </ActionButton>
        }
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'ticketUrl',
        show: false
      },
      {
        accessor: 'idAppAccount',
        show: false
      },
      {
        accessor: 'idWorkflowActionExecution',
        show: false
      }
    ]
  }

  const renderContent = () => {
    const taskGroupContentElementId = `taskGroupContent-${idAppAccount}-${idApp}`
    return (<TaskGroupContent data-testid='taskGroupContent' id={taskGroupContentElementId}>
      <Table
        loading={isLoading}
        columns={getColumns()}
        itemsName='Tasks'
        data={tasks}
        totalCount={tasks.length}
        allowedScopes={[SCOPES.OFFBOARDING_WRITE]}
        header={`${isTicketType ? 'Jira Issues' : 'Tasks'} (${tasks.length})`}
        getTrGroupProps={getTrGroupClassName}
        style={{ minHeight: 'unset' }}
        scrollObjectId={taskGroupContentElementId}
        tableHeaderStyle={{ padding: 0, paddingTop: 8 }}
        forceShowNumberOfResults
      />
    </TaskGroupContent>)
  }

  const toggleIsOpen = () => {
    Analytics.track(`${isOpen ? 'Close' : 'Open'} offboarding-tasks-box`, {
      'Application Name': app.name,
      '# tasks': tasks.length
    })
    setIsOpen(!isOpen)
  }

  return (
    (tasks?.length || isLoading)
      ? (
        <ExpandableSection
          key={`task-${idAppAccount}`}
          isOpen={isOpen}
          onToggle={toggleIsOpen}
          avatar={<RelativeTeamLink to={`/app/${idApp}`}>
            <AppIcon size='medium' appImageUrl={app?.imageUrl} appName={app?.name} hoverable />
          </RelativeTeamLink>}
          title={<Placeholder loading={isLoading}>
            <span>{headerLabel}</span>
            <Counter value={tasks.length} />
          </Placeholder>}
        >
          {renderContent()}
        </ExpandableSection>
      )
      : null
  )
}

export default PendingTasksGroup
