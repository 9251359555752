import { ReactElement, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useSelector } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import { getTransactionsFilterOptionsValuesPerKey, isTransactionsMetadataLoading } from '@selectors/transactions'
import { EMPTY_OBJECT, TRANSACTION_MAPPING_STATUS } from '@root/constants'
import { getColumns as getTransactionColumns } from '@components/expensesTable/columns'
import { mapSortArray } from '@shared/utils'
import config from '@root/config'
import { DynamicWidgetInternalDataPopupProps } from '../types'
import noop from 'lodash/noop'
import { useGetEntityTableData } from '../shared.hooks'

export const MAPPING_STATUSES = Object.values(TRANSACTION_MAPPING_STATUS)

const TransactionDataPopup = (props: DynamicWidgetInternalDataPopupProps): ReactElement | null => {
  const { renderFunc, resources, fields = [], widget } = props

  const idOrg = useSelector(getIdOrg)
  const fieldValues = useSelector(getTransactionsFilterOptionsValuesPerKey)
  const loadingMetadata = useSelector(isTransactionsMetadataLoading)

  const [columns, setColumns] = useState([])

  const { users: usersById = {}, apps: appsById = {} } = resources ?? EMPTY_OBJECT

  const { configurableColumnsOptions, filtersOptions } = useGetEntityTableData({ entityType: widget.entityType, fields })

  useDeepCompareEffect(() => {
    const widgetColumnsConfiguration = fields.reduce((acc, field) => {
      acc[field] = true
      return acc
    }, {})

    setColumns(
        getTransactionColumns({ idOrg, usersById, appsById, displayAppColumn: true, onArchiveActionClicked: noop, onEditActionClicked: noop })
          .filter(col => widgetColumnsConfiguration[col.id || col.accessor] || col.show === false) as any
    )
  }, [idOrg, appsById, usersById, fields])

  const exportToCsv = ({ sort, query, filters }) => {
    const columnsOrder = fields
    const columnsOrdered = columns.sort((a: any, b:any) => columnsOrder.indexOf(a.id || a.accessor) - columnsOrder.indexOf(b.id || b.accessor))
    const csvFields = columnsOrdered.filter((col: any) => ((col.show === undefined || col.show) && !col.hideFromCSV)).map((col: any) => col.id || col.accessor).flatMap(field => field === 'fullName' ? ['firstName', 'lastName', 'email'] : field)

    const sortParam = 'sort=' + mapSortArray(sort).join(',')
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${csvFields.join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`
    const mappingStatusParam = `mappingStatus=${MAPPING_STATUSES.join(',')}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/transactions/csv?${sortParam}&${queryParam}&${filtersParam}&${fieldsParam}&${mappingStatusParam}`
    const newWindow = window.open(url, '_blank') as any
    newWindow.opener = null
  }

  return renderFunc({ columns, filtersOptions, fieldValues, configurableColumnsOptions, fetchFieldValues: () => {}, exportToCsv, loadingMetadata })
}

export default TransactionDataPopup
